/* .nav-link.active {
    background-color: #624BFF !important;
    color: white !important;
    border-bottom: 3px solid #624BFF !important;
  }
  
  .nav-link {
    color: black !important;
    background-color: white;
  } */

.navLink {
  color: black !important;
  background-color: white;
}

.navLinkActive {
  background-color: #624bff !important;
  color: white !important;
  border-bottom: 3px solid #624bff !important;
}

.tablecontainer {
  margin: 20px;
  padding: 30px;
  background: white;
  border-radius: 8px;
}

.searchbar {
  background: #f1f5f9;
  border: none;
  padding: 7px;
  border-radius: 5px;
}

.input_btn {
  margin-bottom: 70px;
}

.data_list {
  overflow-x: auto;
}

.data_list thead th:first-child {
  border-radius: 10px 0 0;
}

.data_list thead th:last-child {
  border-radius: 0 10px 0 0;
}

.data_list thead th {
  color: #454f5b;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  background-color: #eff1f2;
}

.data_list tbody td {
  color: #454f5b;
  font-size: 15px;
  line-height: 18px;
}

.list_pagination li.prev_btn a,
.list_pagination li.next_btn a {
  border: none;
  color: #919eab;
  background-color: transparent;
}

.list_pagination li.prev_btn,
.list_pagination li.next_btn {
  margin: 5px 0 !important;
}

.list_pagination li {
  margin: 5px;
}

.list_pagination .active a {
  background: #637381;
  border: none;
  color: white;
  border-radius: 8px;
}

.list_pagination li a {
  background-color: #eff1f2;
  border-radius: 8px;
  border: none;
  color: black;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
}

li.next_btn.page-item.disabled span {
  border: none;
  background: transparent;
}

li.prev_btn.page-item.disabled span {
  border: none;
  background: transparent;
}

.data_list tbody .data_row:hover td {
  background: #f4f6f8;
}

.list_pagination {
  padding-top: 80px;
}

.bg-lightgray .modal-content {
  background-color: #f1f5f9;
  padding: 40px;
}

.customer_data .input_head {
  margin-bottom: 30px;
}

.customer_data .input_head input {
  outline: none;
}

.customer_data .input_head h5 {
  font-size: 22px;
  font-weight: 500;
  line-height: 26px;
  color: #212b36;
}

.tablist_data li button {
  border: none !important;
}

.tablist_data li button.active {
  background-color: #f6f5ff !important;
  color: #624bff !important;
  border-radius: 8px !important;
}

.tablist_data li button {
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  color: #212b36 !important;
  padding: 12px 24px;
}

.customer_details {
  padding: 30px;
  background: white;
  border-radius: 8px;
}

.detail_person .user_name {
  width: 150px;
  font-size: 14px;
  color: #637381;
}

.detail_person .part_name {
  width: 20px;
  font-size: 16px;
  color: #454f5b;
}

.detail_person .detail_name {
  font-size: 16px;
  color: #454f5b;
}

.separtor.line {
  height: 2px;
  width: 100%;
  background: #e6e6e6;
}

.customer_details h5 {
  font-size: 22px;
  font-weight: 500;
}

.modal-fullscreen .modal-title {
  font-size: 28px;
  color: #624bff;
}

.modal-fullscreen .modal-header button {
  opacity: 0.7;
}

.import_filedata {
  font-size: 20px;
  padding: 28px 0 18px;
}

.modal_header,
.modal_body,
.modal_footer {
  padding: 28px;
}

.modal_header h5 {
  font-size: 24px;
}

.modal_header .btn-close {
  opacity: 0.8;
  font-size: 18px;
}

.info_text .add_info {
  color: #454f5b;
}

.total_duplicate .total_data li p {
  font-size: 14px;
  color: #454f5b;
}

.total_duplicate .total_data li p span {
  color: #212b36;
}

.total_duplicate .allow_dup label {
  font-size: 14px;
  color: #454f5b;
}

.total_duplicate .allow_dup input {
  width: 40px;
  height: 20px;
}

/* floating textarea css start*/
.floating-label {
  font-size: 16px;
  font-weight: 500 !important;
  margin-bottom: 0.5rem;
  /* color: #212b36; */
  /* pointer-events: none;
  color: #212b36;
  left: 22px;
  top: 14px;
  padding: 0 5px;
  transition:
    top 0.3s ease,
    font-size 0.3s ease,
    color 0.3s ease; */
}

.floating-textarea {
  transition: border-color 0.3s ease;
  font-size: 16px;
  padding: 20px;
  color: #212b36;
}

.floating-textarea:focus {
  color: var(--bs-body-color) !important;
  background-color: var(--bs-body-bg) !important;
  border-color: #b1a5ff !important;
  outline: 0 !important;
  box-shadow: 0 0 0 0.25rem rgba(98, 75, 255, 0.25) !important;
}

/* .floating-textarea:focus ~ .floating-label,
.floating-textarea:not(:placeholder-shown) ~ .floating-label {
  top: -8px;
  font-size: 13px;
}
.floating-label-content textarea:focus + label,
.floating-label-content input:focus + label {
  color: #624bff !important;
}
.floating-textarea:focus {
  background-color: white;
} */
.manual_input {
  border: 1px solid #637381 !important;
  height: 56px;
}

/* floating textarea css end*/

table,
th,
td {
  border: none;
}

.tablescroll_imp table thead tr th {
  position: sticky;
  top: 0;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  background: #d9d9d9;
  -webkit-border-radius: 1ex;
}

.tablescroll_imp tbody tr td p {
  font-size: 14px;
  color: #454f5b;
}

.delaysec_input {
  background-color: #f1f5f9;
}

.border_rightcol {
  border-right: 1px solid #d9d9d9;
}

.set-pdf {
  /* height: 600px; */
  width: 650px;
  margin: 0 auto;
}

.uploading_image {
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
  border-radius: 16px;
  height: 550px;
  width: 600px;
  margin: 0 auto;
}

.border_rightcol .edit_add_card {
  padding: 8px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}

.edit_add_card .add_fontstyle {
  font-size: 16px;
}

.color-picker-wrapper input[type="color"] {
  width: 30px;
  height: 30px;
  border-radius: 50% !important;
  appearance: none;
}

/* Specific styles for WebKit browsers */
.color-picker-wrapper input[type="color"]::-webkit-color-swatch-wrapper {
  padding: 0;
  border-radius: 50%;
}

.color-picker-wrapper input[type="color"]::-webkit-color-swatch {
  border-radius: 50%;
}

/* image loader spinner start */
.loading-container {
  position: relative;
  height: 42px;
  width: 42px;
}

.loading-progress {
  position: absolute;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  border: 3px solid #accaff36;

  &::before {
    content: "";
    position: absolute;
    height: 42px;
    width: 42px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #1677ff;
    top: -3px;
    left: -2px;
    animation: spin 1s linear infinite;
  }
}

.cursor-drag {
  cursor: grab !important;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* image loader spinner end */

.contain_image {
  /* border: 1px solid #D9D9D9; */
  /* border-radius: 8px; */
  padding: 10px;
}

.custom-pdfset .konvajs-content {
  height: 100% !important;
  width: 100% !important;
  /* object-fit: contain; */
}

.custom-pdfset .konvajs-content canvas {
  height: 100% !important;
  width: 100% !important;
  /* object-fit: contain; */
  /* object-fit: cover; */
  /* overflow: auto; */
  display: fill;
  border-radius: 8px;
  position: static;
  /* border: 1px solid #D9D9D9; */
}

.custom-pdfset #stage {
  height: 100% !important;
  width: 100% !important;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* .hr-dotted-large {
  border: none;
  height: 10px;
  margin: 20px 0 15px 0;
  background-image: radial-gradient(#212529 40%, transparent 40%);
  background-size: 10px 10px;
  background-repeat: repeat-x;
} */

.hr-dotted-large {
  border: 1px solid #030a13;
}

.box-color-0 {
  background-color: #f8d7da; /* Soft Red */
  border-radius: 15px;
  padding: 8px;
}

.box-color-1 {
  background-color: #d4edda; /* Soft Green */
  border-radius: 15px;
  padding: 8px;
}

.box-color-2 {
  background-color: #cce5ff; /* Soft Blue */
  border-radius: 15px;
  padding: 8px;
}

.box-color-3 {
  background-color: #fff3cd; /* Soft Yellow */
  border-radius: 15px;
  padding: 8px;
}

.box-color-4 {
  background-color: #f5f5f5; /* Light Gray */
  border-radius: 15px;
  padding: 8px;
}

.box-color-5 {
  background-color: #ffe8cc; /* Soft Orange */
  border-radius: 15px;
  padding: 8px;
}

.box-color-6 {
  background-color: #e6d8ff; /* Soft Purple */
  border-radius: 15px;
  padding: 8px;
}

.box-color-7 {
  background-color: #d1f4f2; /* Teal */
  border-radius: 15px;
  padding: 8px;
}

.box-color-8 {
  background-color: #ffeef0; /* Pale Pink */
  border-radius: 15px;
  padding: 8px;
}

.box-color-9 {
  background-color: #eafff5; /* Mint Green */
  border-radius: 15px;
  padding: 8px;
}
.input-number-field-up-arrow {
  height: 20px;
  width: 17px;
  top: 30px;
  right: 7px;
  position: absolute;
  cursor: pointer;
}

.input-number-field-down-arrow {
  height: 20px;
  width: 17px;
  top: 41px;
  right: 7px;
  position: absolute;
  cursor: pointer;
}
