/* ================================================
   Project: Invitewala Reseller Panel
   Author: DCPL Technology Private Limited.
=================================================== */

.advance-widgets-container .advance-widgets:nth-child(6n-5) .icon {
    background-color: var(--purple-two);
    color: var(--purple);
}

.advance-widgets-container .advance-widgets:nth-child(6n-5)::after {
    border-bottom-color: var(--purple-two);
}

.advance-widgets-container .advance-widgets:nth-child(6n-5):hover::after {
    border-bottom-color: var(--purple);
}



.advance-widgets-container .advance-widgets:nth-child(6n-4) .icon {
    background-color: var(--pink);
    color: var(--red);
}

.advance-widgets-container .advance-widgets:nth-child(6n-4)::after {
    border-bottom-color: var(--pink);
}

.advance-widgets-container .advance-widgets:nth-child(6n-4):hover::after {
    border-bottom-color: var(--red);
}



.advance-widgets-container .advance-widgets:nth-child(6n-3) .icon {
    background-color: var(--green-one);
    color: var(--green);
}

.advance-widgets-container .advance-widgets:nth-child(6n-3)::after {
    border-bottom-color: var(--green-one);
}

.advance-widgets-container .advance-widgets:nth-child(6n-3):hover::after {
    border-bottom-color: var(--green);
}



.advance-widgets-container .advance-widgets:nth-child(6n-2) .icon {
    background-color: var(--orange-one);
    color: var(--orange);
}

.advance-widgets-container .advance-widgets:nth-child(6n-2)::after {
    border-bottom-color: var(--orange-one);
}

.advance-widgets-container .advance-widgets:nth-child(6n-2):hover::after {
    border-bottom-color: var(--orange);
}




.advance-widgets-container .advance-widgets:nth-child(6n-1) .icon {
    background-color: var(--cyan);
    color: var(--dark-skyblue);
}

.advance-widgets-container .advance-widgets:nth-child(6n-1)::after {
    border-bottom-color: var(--cyan);
}

.advance-widgets-container .advance-widgets:nth-child(6n-1):hover::after {
    border-bottom-color: var(--dark-skyblue);
}



.advance-widgets-container .advance-widgets:nth-child(6n-0) .icon {
    background-color: var(--muted-twentytwo);
    color: var(--muted-twentythree);
}

.advance-widgets-container .advance-widgets:nth-child(6n-0)::after {
    border-bottom-color: var(--muted-twentytwo);
}

.advance-widgets-container .advance-widgets:nth-child(6n-0):hover::after {
    border-bottom-color: var(--muted-twentythree);
}

.advance-widgets {
    border-radius: 6px;
    position: relative;
    transition: all .2s ease-in-out;

}

.advance-widgets::after {
    content: "";
    position: absolute;
    bottom: 0px;
    left: 0;
    width: 100%;
    height: 100%;
    border-bottom-width: 2px;
    border-bottom-style: solid;
    border-radius: .375rem;
    transition: all .2s ease-in-out;
    z-index: 1;

}

.advance-widgets:hover::after {
    border-bottom-width: 3px;
    /* border-color: #ff9f43; */
    transition: all .2s ease-in-out;

}

.advance-widgets .icon {
    height: 40px;
    width: 40px;
    border-radius: 6px;
}

.advance-widgets h5 {
    font-size: var(--fs-24) !important;
}

.advance-widgets p {
    font-size: var(--fs-15);
    line-height: 20px;
    color: var(--muted);
}