/* ================================================
   Project: Invitewala Reseller Panel
   Author: DCPL Technology Private Limited.
=================================================== */
.custom-select:focus-visible,
.custom-select .css-t3ipsp-control {
    border-color: var(--purple) !important;
    outline-color: var(--purple) !important;
    /* box-shadow: 0 0 0 1px var(--purple) !important; */
    box-shadow: none !important;
}

