/* ================================================
   Project: Invitewala Reseller Panel
   Author: DCPL Technology Private Limited.

      Note: Not Change Any File Postion in this File.
=================================================== */

.cutom-nav-tabs li {
    border-radius: 6px !important;
    overflow: hidden !important;
}

.cutom-nav-tabs li button {
    padding: 14px 28px !important;
    border-radius: 6px;
    overflow: hidden;
    color: var(--black-one) !important;
    transition: color .15s ease-in-out, background-color .15s ease-in-out !important;
    line-height: 20px !important;
}

.cutom-nav-tabs li button p {
    line-height: 24px !important;
    font-size: var(--fs-20);
}

.cutom-nav-tabs li button svg {
    height: 20px;
    width: 20px;
}


.cutom-nav-tabs li button.active,
.cutom-nav-tabs li button.active:hover {
    background-color: var(--purple) !important;
    color: var(--white) !important;
    box-shadow: 0 .125rem .375rem 0 var(--purple-four) !important;
    border: 0px !important;
}

.cutom-nav-tabs li button.active svg,
.cutom-nav-tabs li button.active:hover svg {
    color: var(--white) !important;
}

.cutom-nav-tabs li button:hover {
    background-color: var(--purple-two) !important;
    color: var(--purple-five) !important;
    transition: color .15s ease-in-out, background-color .15s ease-in-out !important;
}

.cutom-nav-tabs li button:hover svg {
    color: var(--purple-five) !important;
    transition: color .15s ease-in-out, background-color .15s ease-in-out !important;
}


.cutom-nav-tabs li button .tabs-gap {
    gap: 12px !important;
}

.common-tab-spacing {
    margin-bottom: 32px;
}

/* <------- Media Query Start -------> */
@media screen and (max-width:1920px) {

    .cutom-nav-tabs li button {
        padding: 8px 22px !important;
        line-height: 20px !important;
    }

    .cutom-nav-tabs li button p {
        line-height: 19px !important;
        font-size: var(--fs-15);
    }

    .cutom-nav-tabs li button svg {
        height: 16px;
        width: 16px;
    }

    .cutom-nav-tabs li button .tabs-gap {
        gap: 6px !important;
    }

    .common-tab-spacing {
        margin-bottom: 24px;
    }
}

/* <------- Media Query End -------> */