/* ================================================
   Project: Invitewala Reseller Panel
   Author: DCPL Technology Private Limited.
=================================================== */

/* <-----  Step Folder Atteched Bellow -----> */
@import url('./steps/index.css');


.custpmize-grid {
   display: grid;
   grid-template-columns: repeat(4, 1fr);
}

.action-drops button,
.action-drops button:focus,
.action-drops button:focus-within,
.action-drops button:hover,
.action-drops button:active,
.action-drops button:focus-visible {
   background-color: var(--purple) !important;
   font-size: var(--fs-19) !important;
   line-height: var(--lh-22);
   padding: 12px 28px;
   box-shadow: none !important;
}

.action-drops .action-drops-menu {
   padding: 20px 28px;
   gap: 12px;

}

.action-drops .action-drops-menu a {
   font-size: var(--fs-19);
   line-height: var(--lh-22);
   color: var(--black-one) !important;
   gap: 12px;
}

.action-drops .action-drops-menu a svg {
   height: 20px;
   width: 20px;
}

.action-drops .action-drops-menu a:hover,
.action-drops .action-drops-menu a:active,
.action-drops .action-drops-menu a:focus-visible,
.action-drops .action-drops-menu a:focus {
   background-color: transparent;
   color: var(--black-one) !important;
}

.action-drops .action-drops-menu .divider {
   border-bottom: 1px solid #dee2e6;
}

.action-drops button::after {
   display: none;
}

.pdf-viewer-custom #modal-wrapper,
.pdf-viewer-custom .fixed.inset-0.bg-black.z-50 {
   z-index: 10001;
   /* position: relative; */
   overflow-y: hidden !important;
}

.pdf-viewer-custom .fixed.inset-0.bg-black.z-50{
   background-color: rgba(0, 0, 0,0.65) !important;
}


/* ------ Media Query Strat ------- */
@media screen and (max-width:1920px) {

   .action-drops button,
   .action-drops button:focus,
   .action-drops button:focus-within,
   .action-drops button:hover,
   .action-drops button:active,
   .action-drops button:focus-visible {
      font-size: var(--fs-15) !important;
      line-height: var(--lh-22);
      padding: 8px 20px;
   }

   .action-drops .action-drops-menu {
      padding: 14px 16px;
      gap: 8px;
   }

   .action-drops .action-drops-menu a {
      font-size: var(--fs-15);
      line-height: var(--lh-20);
      gap: 8px;
   }

   .action-drops .action-drops-menu a svg {
      height: 16px;
      width: 16px;
   }
}

@media screen and (max-width:1499px) {
   .custpmize-grid {
      grid-template-columns: repeat(3, 1fr);
   }
}

@media screen and (max-width:1399px) {
   .custpmize-grid {
      grid-template-columns: repeat(4, 1fr);
   }
}

@media screen and (max-width:1100px) {
   .custpmize-grid {
      grid-template-columns: repeat(3, 1fr);
   }
}

@media screen and (max-width:992px) {
   .custpmize-grid {
      grid-template-columns: repeat(2, 1fr);
   }
}

/* ------ Media Query End ------- */