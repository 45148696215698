/* class for showing number below connected status in customer list view */
.customer_list_no_show {
    margin-top: 6px;
    font-size: var(--fs-18);
    margin-left: 3px;
    color: #0d773c;
}

/* Class for givng Minimum height to tables */
/* .custom-style {
    height: 90vh;
    min-height: 90vh;
    overflow-y: scroll !important;
  } */


/* <------- Media Query Start --------> */
@media screen and (max-width:1920px) {
    .customer_list_no_show {
        margin-top: 4px;
        font-size: var(--fs-14);
    }

}

/* <------- Media Query End --------> */