/* ================================================
   Project: Invitewala Reseller Panel
   Author: DCPL Technology Private Limited.
=================================================== */

.custom-alert {
    padding: 10px 15px !important;
    border-radius: 6px !important;
    line-height: 22px !important;
}


.warning-alert {
    background-color: var(--orange-one) !important;
    border-color: var(--orange-one) !important;
    color: var(--orange) !important;
}

.warning-alert span svg {
    color: var(--orange) !important;
}