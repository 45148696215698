/* ================================================
   Project: Invitewala Reseller Panel
   Author: DCPL Technology Private Limited.

   Note: Not Change Any Css Postion in this File.
=================================================== */

.common-btn {
    padding: 12px 28px !important;
    font-size: var(--fs-19) !important;
}

.common-btn .common-btn-gap {
    gap: 10px;
}

.common-btn svg {
    height: 22px;
    width: 22px;
}

/* Primary Button Style Start */
.btn-primary {
    background-color: var(--purple) !important;
    color: var(--white) !important;
}


.btn-primary:hover {
    background-color: var(--purple-five) !important;
}

.primary-shadow {
    box-shadow: 0 .125rem .375rem 0 var(--purple-four);
}

.btn-outline-primary,
.btn-outline-primary-icon {
    border: 1px solid var(--purple) !important;
    color: var(--purple) !important;
}

.btn-outline-primary-icon {
    height: 52px;
    width: 52px;
}

.btn-outline-primary-icon svg{
    height: 21px !important;
    width: 21px !important;
}

.btn-outline-primary-icon:hover {
    background-color: var(--purple) !important;
}

.btn-outline-primary-icon:hover svg path {
    stroke: var(--white);

}


/* Muted Button Style Start */
.btn-muted {
    background-color: var(--muted-seven) !important;
    color: var(--muted-one) !important;
}

.btn-muted:hover {
    background-color: var(--muted-twentynine) !important;
}

/* Danger-outline Button Style Start */
.btn-danger {
    background-color: var(--red) !important;
    color: var(--white) !important;
}

.btn-danger:hover {
    background-color: var(--red-one) !important;
}

.btn-outline-danger {
    border: 1px solid var(--red) !important;
    color: var(--red) !important;
}

.btn-outline-danger:hover {
    background-color: transparent !important;
}

/* <------- Media Query Start -------> */
@media screen and (max-width:1920px) {
    .common-btn {
        padding: 7.5px 20px !important;
        font-size: var(--fs-15) !important;
    }

    .common-btn .common-btn-gap {
        gap: 4px;
    }

    .common-btn svg {
        height: 16px;
        width: 16px;
    }

    .btn-outline-primary-icon {
        height: 40px;
        width: 40px;
    }

    .btn-outline-primary-icon svg{
        height: 17px !important;
        width: 17px !important;
    }
}

/* <------- Media Query End -------> */