/* .nav-link.active {
    background-color: #624BFF !important;
    color: white !important;
    border-bottom: 3px solid #624BFF !important;
  }
  
  .nav-link {
    color: black !important;
    background-color: white;
  } */

  .navLink {
    color: black !important;
    background-color: white;
  }
  
  .navLinkActive {
    background-color: #624BFF !important;
    color: white !important;
    border-bottom: 3px solid #624BFF !important;
  }
  