/* ================================================
   Project: Invitewala Reseller Panel
   Author: DCPL Technology Private Limited.
=================================================== */

.custon-status-chip {
    padding: 9px 16px !important;
    font-size: var(--fs-18) !important;
    line-height: 18px !important;
}

.bg-chip-red {
    background-color: var(--pink) !important;
    color: var(--red) !important;
}

.bg-chip-green {
    background-color: var(--green-one) !important;
    color: var(--green) !important;
}

.bg-chip-yellow {
    background-color: var(--yellow-one) !important;
    color: var(--yellow) !important;
}

.bg-chip-blue {
    background-color: var(--dark-blue-one) !important;
    color: var(--dark-blue) !important;
}

.bg-chip-ornage {
    background-color: var(--orange-one) !important;
    color: var(--orange) !important;
}

.bg-chip-blue {
    background-color: var(--purple-two) !important;
    color: var(--purple) !important;
}

.bg-chip-dark-blue {
    background-color: var(--dark-blue-one) !important;
    color: var(--dark-blue) !important;
}

.bg-chip-dark-cyan {
    background-color: var(--dark-cyan-one) !important;
    color: var(--dark-cyan) !important;
}

/* <------- Media Query Start -------> */
@media screen and (max-width:1920px) {
    .custon-status-chip {
        padding: 5px 10px !important;
        font-size: var(--fs-13) !important;
        line-height: 13px !important;
    }
}

/* <------- Media Query End -------> */