/* ================================================
   Project: Invitewala Reseller Panel
   Author: DCPL Technology Private Limited.
=================================================== */

.campaigns-type-content {
    margin: 32px;
}


.campaigns-type-content .campaigns-type-header h5 {
    font-size: var(--fs-24);
    color: var(--black-one);
    line-height: 30px;
}

.campaigns-type-content .campaigns-type-header p {
    color: var(--muted);
    line-height: 28px;
    font-size: var(--fs-20);
}


.campaigns-type-content .switch-tab .icon {
    height: 52px;
    width: 52px;
    background-color: var(--purple);
}

.campaigns-type-content .switch-tab .icon svg {
    height: 30px;
    width: 30px;
}

.campaigns-type-content .switch-tab p {
    font-size: var(--fs-20);
    color: var(--black-one);
}

/* <------- Media Query Start -------> */
@media screen and (max-width:1920px) {

    .campaigns-type-content {
        margin: 24px;
    }

    .campaigns-type-content .campaigns-type-header h5 {
        font-size: var(--fs-18);
        line-height: 22px;
    }

    .campaigns-type-content .campaigns-type-header p {
        line-height: 20px;
        font-size: var(--fs-15);
    }


    .campaigns-type-content .switch-tab .icon {
        height: 38px;
        width: 38px;
    }

    .campaigns-type-content .switch-tab .icon svg {
        height: 24px;
        width: 24px;
    }

    .campaigns-type-content .switch-tab p {
        font-size: var(--fs-15);
    }
}

/* <------- Media Query End -------> */