/* ================================================
   Project: Invitewala Reseller Panel
   Author: DCPL Technology Private Limited.
=================================================== */

.custom-pagination {
    gap: 12px !important;
}

.custom-pagination li {
    height: 46px;
    width: 46px;
    background-color: var(--muted-twentyfive);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    color: var(--black-one);
    font-size: var(--fs-20);
    cursor: pointer !important;
}

.custom-pagination li svg {
    height: 24px;
    width: 24px;
}

.custom-pagination-visible-item {
    min-height: 46px;
    font-size: var(--fs-20);
}

.custom-pagination-visible-item svg {
    height: 24px;
    width: 24px;
}


.custom-pagination li.active {
    background-color: var(--purple);
    color: var(--white);
    cursor: pointer !important;
}

.custom-pagination li.active:hover {
    background-color: var(--purple);
    color: var(--white);
}

.custom-pagination li:hover {
    background-color: var(--purple-two);
    color: var(--purple);
}

.custom-pagination li.disabled {
    background-color: var(--muted-twentysix);
    cursor: default !important;
}

.custom-pagination li.disabled svg {
    color: var(--muted);
}

.custom-pagination li.disabled:hover {
    background-color: var(--muted-twentysix);
}

.custom-pagination li.disabled:hover svg {
    color: var(--muted);
}

.custom-pagination li.page-item {
    cursor: default !important;
}

/* <------- Media Query Start -------> */
@media screen and (max-width:1920px) {
    .custom-pagination {
        gap: 6px !important;
    }


    .custom-pagination-visible-item {
        min-height: 38px;
        font-size: var(--fs-15);
    }

    .custom-pagination-visible-item svg {
        height: 20px;
        width: 20px;
    }


    .custom-pagination li {
        height: 38px;
        width: 38px;
        font-size: var(--fs-15);
    }

    .custom-pagination li svg {
        height: 18px;
        width: 18px;
    }
}

/* <------- Media Query End -------> */