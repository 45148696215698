/* ================================================
   Project: Invitewala Reseller Panel
   Author: DCPL Technology Private Limited.
=================================================== */

.common-form-label {
    font-size: var(--fs-18);
    line-height: 24px;
    color: var(--black-one);
    letter-spacing: inherit;
    text-transform: inherit;
}

/* <------- Media Query Start -------> */
@media screen and (max-width:1920px) {
    .common-form-label {
        font-size: var(--fs-13);
        line-height: 18px;
    }
}

/* <------- Media Query End -------> */