/* ================================================
   Project: Invitewala Reseller Panel
   Author: DCPL Technology Private Limited.
=================================================== */

.common-action-btn .drop-btn {
    height: 45px;
    width: 45px;
    border-radius: 50%;
    box-shadow: none;
    transition: all .2s ease-in-out;
}

.common-action-btn .drop-btn svg {
    height: 24px;
    width: 24px;
}

.common-action-btn .drop-btn:hover,
.common-action-btn .drop-btn:focus,
.common-action-btn .drop-btn:active {
    background-color: var(--muted-twentyfour);
    transition: all .2s ease-in-out;
}

.common-action-btn .action-drop-menu {
    box-shadow: 0 .25rem 1.125rem 0 var(--muted-eighteen) !important;
}

.common-action-btn .action-drop-menu .action-drop-item {
    font-size: var(--fs-20) !important;
    color: var(--black-one) !important;
    padding: 12px 20px;
    margin-top: 4px;

}

.common-action-btn .action-drop-menu .action-drop-item:first-child {
    margin-top: 0px;
}

.common-action-btn .action-drop-menu .action-drop-item:hover {
    background-color: var(--muted-seventeen);
}

.common-action-btn .drop-btn::after {
    display: none;
}

.daterangepicker .ranges ul {
    width: 200px;
}

.daterangepicker .ranges li {
    font-size: var(--fs-17);
    padding: 12px 20px;
}


/* <------- Media Query Start -------> */
@media screen and (max-width:1920px) {
    .common-action-btn .drop-btn {
        height: 38px;
        width: 38px;
    }


    .common-action-btn .drop-btn svg {
        height: 20px;
        width: 20px;
    }

    .common-action-btn .action-drop-menu .action-drop-item {
        font-size: var(--fs-15) !important;
        padding: 9px 16px;
        margin-top: 2px;

    }

    .daterangepicker .ranges ul {
        width: 140px;
    }

    .daterangepicker .ranges li {
        font-size: var(--fs-12);
        padding: 8px 12px;
    }
}

/* <------- Media Query End -------> */