// ------------------------------------------------------//
// <-- Project: Whatsapp Inviation Admin Panel --> //
// <-- Athore: DCPL Technology Pvt Ltd. --> //
// ------------------------------------------------------//

// ---------------------------------------//
// ---> 1. dashboard-Detail Page Style
// ---------------------------------------//

// <----- 1. dashboard-Detail Page Style Start -----> //

.cerdit-history-content {
    .cedit-h-card {
        background-color: white;

        p {
            font-weight: 400;
            color: $gray-600;
            font-size: 20px;
            margin-bottom: 8px ;
        }

        span {
            font-size: 24px !important;
            font-weight: 500 !important;
            color: $gray-900 !important;
        }
    }
}

.cerdit-log-content {
    .cedit-h-card {
        background-color: $gray-800;

        p {
            font-weight: 400;
            color: $gray-600;
            font-size: 16px;
            margin-bottom: 2px !important;
        }

        span {
            font-size: 20px !important;
            font-weight: 500 !important;
            color: $gray-900 !important;
        }
    }
}

// <----- 1. dashboard-Detail Page Style End -----> //

/* <------- Media Query Start -------> */
@media screen and (max-width: 1920px) {
    .cerdit-history-content {
        .cedit-h-card {
            p {
                font-size: 16px;
                margin-bottom: 2px !important;
            }

            span {
                font-size: 20px !important;
            }
        }
    }

    .cerdit-log-content {
        .cedit-h-card {
            p {
                font-size: 16px;
                margin-bottom: 2px !important;
            }

            span {
                font-size: 20px !important;
            }
        }
    }
}
/* <------- Media Query End -------> */
