/* ================================================
   Project: Invitewala Reseller Panel
   Author: DCPL Technology Private Limited.
=================================================== */

.dashboard-content .dashboard-header {
    padding: 20px 28px;
}

.dashboard-content .dashboard-header .date-icon {
    height: 52px;
    width: 52px;
    background-color: var(--purple-two);
}

.dashboard-content .dashboard-header .date-icon svg {
    height: 30px;
    width: 30px;
}

.dashboard-content .dashboard-header p {
    line-height: 26px;
    font-size: var(--fs-20);
    color: var(--muted);
}

.dashboard-content .dashboard-header small {
    font-size: var(--fs-18);
    line-height: 22px;
    color: var(--black-one);
}

.dashboard-content .dashboard-header .date-text svg {
    height: 24px;
    width: 24px;
}

.dashboard-content .dashboard-header .date-text span {
    font-size: 20px;
}


.dashboard-content .dashboard-body {
    margin-top: 48px;
}

.dashboard-content .dashboard-body h4 {
    font-size: var(--fs-30);
    color: var(--black);
}

.dashboard-content .dashboard-body .dashboard-body-overview h5 {
    font-size: var(--fs-22);
    color: var(--black-one);
}


.dashboard-content .dashboard-body .custom-year-select select {
    font-size: var(--fs-20);
    color: var(--purple);
    border-color: var(--purple);
    font-weight: 500;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCAxNiAxNic+PHBhdGggZmlsbD0nbm9uZScgc3Ryb2tlPScjQTU1OTZBJyBzdHJva2UtbGluZWNhcD0ncm91bmQnIHN0cm9rZS1saW5lam9pbj0ncm91bmQnIHN0cm9rZS13aWR0aD0nMicgZD0nbTIgNSA2IDYgNi02Jy8+PC9zdmc+');
}


.dashboard-content .dashboard-body .custom-year-select select option {
    color: var(--black);
}

.dashboard-content .dashboard-body .total-campaign-text h4 {
    font-size: var(--fs-28);
    color: var(--black);
}

.dashboard-content .dashboard-body .total-campaign-text h4.purple {
    color: var(--purple) !important;
}

.dashboard-content .dashboard-body .total-campaign-text h4.orange {
    color: #69464F !important;
}

.dashboard-content .dashboard-body .total-campaign-text p {
    font-size: var(--fs-20);
    color: var(--muted);
}

.dashboard-content .dashboard-body .total-campaign-text span {
    height: 16px;
    width: 16px;
    background-color: var(--purple);
}

.dashboard-content .dashboard-body .total-campaign-text span.orange {
    height: 16px;
    width: 16px;
    background-color: #69464F !important;
}

/* <------- Media Query Strat -------> */
@media screen and (max-width:1920px) {
    .dashboard-content .dashboard-header {
        padding: 12px 24px;
    }

    .dashboard-content .dashboard-header .date-icon {
        height: 40px;
        width: 40px;
    }

    .dashboard-content .dashboard-header .date-icon svg {
        height: 24px;
        width: 24px;
    }


    .dashboard-content .dashboard-header p {
        line-height: 20px;
        font-size: var(--fs-15);
    }

    .dashboard-content .dashboard-header small {
        font-size: var(--fs-14);
        line-height: 15px;
    }

    .dashboard-content .dashboard-header .date-text svg {
        height: 16px;
        width: 16px;
    }

    .dashboard-content .dashboard-header .date-text span {
        font-size: 15px;
    }

    .dashboard-content .dashboard-body {
        margin-top: 40px;
    }

    .dashboard-content .dashboard-body h4 {
        font-size: var(--fs-24);
    }

    .dashboard-content .dashboard-body .dashboard-body-overview h5 {
        font-size: var(--fs-17);
    }


    .dashboard-content .dashboard-body .custom-year-select select {
        font-size: var(--fs-15);
    }

    .dashboard-content .dashboard-body .total-campaign-text h4 {
        font-size: var(--fs-22);
    }

    .dashboard-content .dashboard-body .total-campaign-text p {
        font-size: var(--fs-15);
        color: var(--muted);
    }

    .dashboard-content .dashboard-body .total-campaign-text span {
        height: 12px;
        width: 12px;
    }

    .dashboard-content .dashboard-body .total-campaign-text span.orange {
        height: 12px;
        width: 12px;
    }
}

/* <------- Media Query End -------> */