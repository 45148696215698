/* ================================================
   Project: Invitewala Reseller Panel
   Author: DCPL Technology Private Limited.
=================================================== */

.br-2_5 {
    border-width: 2.5px !important;
}

.btn-loader-wh-18 {
    height: 18px !important;
    width: 18px !important;
}

.btn-loader-wh-20 {
    height: 20px !important;
    width: 20px !important;
}

.btn-loader-wh-22 {
    height: 22px !important;
    width: 22px !important;
}

.btn-loader-wh-24 {
    height: 24px !important;
    width: 24px !important;
}

.btn-loader-wh-26 {
    height: 26px !important;
    width: 26px !important;
}

.btn-loader-wh-28 {
    height: 28px !important;
    width: 28px !important;
}

.btn-loader-wh-30 {
    height: 30px !important;
    width: 30px !important;
}

.btn-loader-wh-32 {
    height: 32px !important;
    width: 32px !important;
}