/* ================================================
   Project: Invitewala Reseller Panel
   Author: DCPL Technology Private Limited.

   Note: Not Change Any File Postion in this File.
=================================================== */

/* <-----  Layout Stylesheet File Atteched Bellow -----> */
@import url('./layout/index.css');


/* <-----  Pages Stylesheet File Atteched Bellow -----> */
@import url('./pages/index.css');


.customer-tbl-list {
   height: 625px !important;
}

.campaign-tbl-list {
   height: 565px !important;
}

.credits-history-tbl-list {
   height: 508px !important;
}

.recharge-history-tbl-list {
   height: 580px !important;
}

.campaigns-details-tbl-list {
   height: 565px !important;
}

.customer-details-tbl-list{
   height: 632px !important;
}

.new-campaigns-detail-tbl-list{
   height: 500px !important;
}