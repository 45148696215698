/* ================================================
   Project: Invitewala Reseller Panel
   Author: DCPL Technology Private Limited.
=================================================== */

.add-message-content h5 {
    font-size: var(--fs-24);
    color: var(--black-one);
    line-height: 28px;
}

.add-message-content p {
    color: var(--muted);
    line-height: 28px;
    font-size: var(--fs-20);
}

.add-message-content .addmsg-textarea {
    min-height: 200px;
}

.emogis-icons {
    gap: 16px;
}

.emogis-icons svg {
    height: 25px;
    width: 25px;
}

/* <------- Media Query Start -------> */
@media screen and (max-width:1920px) {
    .add-message-content h5 {
        font-size: var(--fs-18);
        line-height: 22px;
    }

    .add-message-content p {
        line-height: 20px;
        font-size: var(--fs-15);
    }

    .add-message-content .addmsg-textarea {
        min-height: 150px;
    }

    .emogis-icons {
        gap: 8px;
    }

    .emogis-icons svg {
        height: 18px;
        width: 18px;
    }
}

/* <------- Media Query End -------> */