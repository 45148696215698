// ------------------------------------------------------//
// <-- Project: Whatsapp Inviation Admin Panel --> //
// <-- Athore: DCPL Technology Pvt Ltd. --> //
// ------------------------------------------------------//

// ---------------------------------------//
// ---> 1. Logical Style
// ---------------------------------------//

// <----- 1. Logical Style Start -----> //
.pg-title {
    font-size: 24px;
    color: $gray-800;
}

.pg-header-link {
    gap: 6px;
    a {
        font-size: 14px;
        color: $primary;
    }

    p {
        font-size: 14px;
    }

    .pg-link-deactive {
        color: $gray-800;

        &:hover {
            color: $primary;
        }
    }
}

.pg-sub-title {
    p {
        font-size: 21px;
        color: $gray-800;
        font-weight: 600;
    }
}

.cursor-pointer {
    cursor: pointer;
}

.sub-heading {
    span {
        height: 8px;
        width: 8px;
        background-color: $gray-800;
        display: inline-block;
    }
    p {
        font-size: 17px;
        color: $gray-800;
        font-weight: 600;
    }
}

.table-tit-head {
    p {
        font-size: 18px;
        font-weight: 600;
        color: $gray-800;
        line-height: 20px;
    }

    input {
        border: 1px solid $gray-300 !important;
        padding: 6px 16px 6px 36px;
        font-size: 15px;
        outline: none !important;
        min-width: 300px !important;

        &:focus,
        &:focus-visible,
        &:active {
            border: 1px solid $primary !important;
            outline: none !important;
        }

        &::placeholder {
            font-size: 15px;
            // font-weight: 500;
            outline: none !important;
        }
    }
}

.primary-pg-title {
    h3 {
        color: $primary;
    }

    .back-arrow {
        height: 38px;
        width: 38px;

        &:hover {
            background-color: #624bff26;
        }
    }
}

.cu-toster {
    p {
        font-size: 15px;
    }
}

.white-space-nowrap {
    white-space: nowrap !important;
}

.custom-backdrop {
    background-color: #22222280;
}

::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

::-webkit-scrollbar-thumb {
    background: #d9d9d9;
    -webkit-border-radius: 1ex;
}

.tablecontainer {
    padding: 30px;
}
// <----- 1. Logical Style Start -----> //
