/* ================================================
   Project: Invitewala Reseller Panel
   Author: DCPL Technology Private Limited.
=================================================== */
.forgot-password-content {
    min-height: 100vh;
}

.forgot-password-content .forgot-password-sub-conetnt {
    max-width: 650px;
}

.forgot-password-content .forgot-password-sub-conetnt .site-logo {
    width: 280px;
}

.forgot-password-content .forgot-password-sub-conetnt::before {
    width: 338px;
    height: 333px;
    content: " ";
    z-index: -1;
    background-repeat: no-repeat;
    position: absolute;
    top: -75px;
    left: -75px;
    background-image: url('/public/assets/images/login/shapes/shape-top.svg');
}

.forgot-password-content .forgot-password-sub-conetnt::after {
    width: 280px;
    height: 280px;
    content: " ";
    z-index: -1;
    background-repeat: no-repeat;
    position: absolute;
    bottom: -153px;
    right: -186px;
    background-image: url('/public/assets/images/login/shapes/shape-bottom.svg');
}

.forgot-password-content .forgot-password-sub-conetnt .forgot-password-card .welcome-content h4 {
    font-size: var(--fs-32);
    color: var(--black-one);
    line-height: 48px;
}

.forgot-password-content .forgot-password-sub-conetnt .forgot-password-card .welcome-content p {
    font-size: var(--fs-20);
    line-height: 28px;
    color: var(--muted);
}

.forgot-password-content .forgot-password-sub-conetnt .forgot-password-card .backtologin-link {
    margin-bottom: 40px;
}

.forgot-password-content .forgot-password-sub-conetnt .forgot-password-card .backtologin-link a {
    font-size: var(--fs-20);
    color: var(--purple);
}

/* <------- Media  Query Start -------> */
@media screen and (max-width:1920px) {

    .forgot-password-content .forgot-password-sub-conetnt {
        max-width: 460px;
    }

    .forgot-password-content .forgot-password-sub-conetnt .site-logo {
        height: 44px;
        width: 200px;
    }

    .forgot-password-content .forgot-password-sub-conetnt::before {
        width: 238px;
        height: 233px;
        top: -35px;
        left: -45px;
    }

    .forgot-password-content .forgot-password-sub-conetnt::after {
        width: 180px;
        height: 180px;
        bottom: -30px;
        right: -56px;
    }

    .forgot-password-content .forgot-password-sub-conetnt .forgot-password-card .welcome-content h4 {
        font-size: var(--fs-24);
        line-height: 38px;
    }

    .forgot-password-content .forgot-password-sub-conetnt .forgot-password-card .welcome-content p {
        font-size: var(--fs-15);
        line-height: 21px;
    }

    .forgot-password-content .forgot-password-sub-conetnt .forgot-password-card .backtologin-link {
        margin-bottom: 32px;
    }

    .forgot-password-content .forgot-password-sub-conetnt .forgot-password-card .backtologin-link a {
        font-size: var(--fs-15);
    }
}

/* <------- Media  Query End -------> */