/* ================================================
   Project: Invitewala Reseller Panel
   Author: DCPL Technology Private Limited.
=================================================== */
.profile-content h4 {
    font-size: var(--fs-30);
    color: var(--black);
}

.profile-content .profile-icon .icon {
    height: 52px;
    width: 52px;
    font-size: var(--fs-18);
    background-color: var(--purple);
    letter-spacing: 1px;
}

.profile-content .edit-link {
    color: var(--purple) !important;
    text-decoration: underline;
    font-size: var(--fs-20);
}

.profile-content .edit-link:hover {
    background-color: transparent !important;
}

.profile-content h6 {
    line-height: 28px;
    font-size: var(--fs-20);
    color: var(--black-one);
}

.profile-content p {
    line-height: 28px;
    font-size: var(--fs-20);
    color: var(--muted);
}

/* <------- Media Query Start -------> */
@media screen and (max-width:1920px) {
    .profile-content h4 {
        font-size: var(--fs-24);
    }

    .profile-content .profile-icon .icon {
        height: 40px;
        width: 40px;
        font-size: var(--fs-13);
    }

    .profile-content .edit-link {
        font-size: var(--fs-15);
    }

    .profile-content h6 {
        line-height: 22px;
        font-size: var(--fs-15);
    }

    .profile-content p {
        line-height: 20px;
        font-size: var(--fs-15);
    }
}

/* <------- Media Query End -------> */