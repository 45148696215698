/* ================================================
   Project: Invitewala Reseller Panel
   Author: DCPL Technology Private Limited.
=================================================== */

.custom-navigationbar {
    border-radius: 6px;
    z-index: 1000;
    box-shadow: 0 .125rem .5rem 0 var(--muted-sixteen);
    background-color: var(--white-four) !important;
    top: 20px;
    padding: 14px 30px;
}

.custom-navigationbar .pg-title {
    color: var(--black-one);
    font-size: 28px !important;
}

.custom-navigationbar .profile-menu .profile-submen {
    box-shadow: 0 .25rem 1.125rem 0 var(--muted-eighteen);
}


.custom-navigationbar .profile-menu .profile-toggle,
.custom-navigationbar .profile-menu .profile-submenu .profile-menu-item .profile-user {
    height: 48px;
    width: 48px;
}

.custom-navigationbar .profile-menu .profile-toggle,
.custom-navigationbar .profile-menu .profile-submenu .profile-menu-item .profile-user {
    height: 48px;
    width: 48px;
    --bs-btn-color: transparent !important;
    --bs-btn-hover-color: transparent !important;
    --bs-btn-active-color: transparent !important;
}

.user-profile-icon {
    height: 48px;
    width: 48px;
    background: var(--purple);
    color: white;
    font-size: large;
    text-transform: uppercase;

}

.custom-navigationbar .profile-menu .profile-submenu .profile-menu-item {
    line-height: 24px !important;
}

.custom-navigationbar .profile-menu .profile-toggle::after,
.custom-navigationbar .profile-menu .profile-submenu .profile-menu-item .profile-user::after {
    display: none !important;
}

.custom-navigationbar .profile-menu .profile-toggle::before,
.custom-navigationbar .profile-menu .profile-submenu .profile-menu-item .profile-user::before {
    content: "";
    position: absolute;
    bottom: 0;
    right: 3px;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    box-shadow: 0 0 0 2px var(--white);
    background-color: var(--green);
}

.custom-navigationbar .profile-menu .profile-submenu .profile-menu-item:hover {
    border-radius: 6px;
    background-color: var(--muted-seventeen);
    color: var(--black-one);
}

.custom-navigationbar .profile-menu .profile-submenu .profile-menu-item h6 {
    font-size: var(--fs-20);
    color: var(--black-one);
    line-height: 26px;
}

.custom-navigationbar .profile-menu .profile-submenu .profile-menu-item .post-tag {
    font-size: var(--fs-16);
    color: var(--muted-four);
    line-height: 24px;
}

.custom-navigationbar .profile-menu .profile-submenu .profile-menu-item span {
    font-size: var(--fs-20);
    color: var(--black-one);
}

.custom-navigationbar .profile-menu .profile-submenu .logout-btn:hover {
    background-color: transparent !important;
}

.custom-navigationbar .profile-menu .profile-submenu .logout-btn button {
    padding: 10px 24px;
    background-color: var(--red);
    border-color: var(--red);
    border-radius: 6px;
    font-size: var(--fs-16);
}

.custom-navigationbar .profile-menu .profile-submenu .logout-btn button:hover {
    background-color: var(--red-one);
    border-color: var(--red-one);
}

.custom-navigationbar .profile-menu .profile-submenu .profile-submenu-divider {
    border-top: 1px solid var(--muted-six);
    margin: 16px -20px;
}

.custom-navigationbar .profile-menu .profile-submenu {
    min-width: 300px;
}


/* <------- Media Query Start -------> */
@media screen and (max-width:1920px) {
    .custom-navigationbar {
        top: 16px;
        padding: 8px 24px;
    }

    .custom-navigationbar .pg-title {
        font-size: 22px !important;
    }


    .custom-navigationbar .profile-menu .profile-toggle,
    .custom-navigationbar .profile-menu .profile-submenu .profile-menu-item .profile-user {
        height: 40px;
        width: 40px;
    }

    .custom-navigationbar .profile-menu .profile-toggle,
    .custom-navigationbar .profile-menu .profile-submenu .profile-menu-item .profile-user {
        height: 40px;
        width: 40px;
    }

    .user-profile-icon {
        height: 40px;
        width: 40px;
        font-size: small;
        text-align: none;
    }

    .custom-navigationbar .profile-menu .profile-toggle::before,
    .custom-navigationbar .profile-menu .profile-submenu .profile-menu-item .profile-user::before {
        right: 3px;
        width: 8px;
        height: 8px;
    }

    .custom-navigationbar .profile-menu .profile-submenu .profile-menu-item {
        line-height: 20px !important;
    }

    .custom-navigationbar .profile-menu .profile-submenu .profile-menu-item h6 {
        font-size: var(--fs-15);
        line-height: 22px;
    }

    .custom-navigationbar .profile-menu .profile-submenu .profile-menu-item .post-tag {
        font-size: var(--fs-13);
        line-height: 20px;
    }

    .custom-navigationbar .profile-menu .profile-submenu .profile-menu-item span {
        font-size: var(--fs-15);
    }

    .custom-navigationbar .profile-menu .profile-submenu .logout-btn button {
        padding: 5px 14px;
        font-size: var(--fs-13);
    }

    .custom-navigationbar .profile-menu .profile-submenu .profile-submenu-divider {
        margin: 4px -8px;
    }

    .custom-navigationbar .profile-menu .profile-submenu {
        min-width: 224px;
    }

}

/* <------- Media Query End -------> */