// Prism



/* Code blocks */
code[class*="language-"], pre[class*="language-"]{
  
  font-family: $font-family-base;
}
pre[class*="language-"] {
  padding: 1em;
  overflow: auto;
  
  border-radius: 0.5rem !important;
  margin: 0;
}
:not(pre) > code[class*="language-"],
pre[class*="language-"] {
  background: $gray-900;
}


div.code-toolbar {
  position: relative;

  > .toolbar {
    position: absolute;
    top: 0;
    right: 0;
    transition: opacity 0.3s ease-in-out;
    opacity: 0;
    padding: .5rem;
  }

  &:hover > .toolbar, &:focus-within > .toolbar {
    opacity: 1;
  }

  > .toolbar > .toolbar-item {
    display: inline-block;

    > {
      a {
        cursor: pointer;
      }

      button {
        background: none;
        border: 0;
        color: $dark;
        font: inherit;
        line-height: normal;
        overflow: visible;
        padding: 0;

      }

      a, button, span {
        color: $dark;
        font-size: .75rem;
        padding: .25rem .5rem;
        background: $white;
      font-weight: 600;
        border-radius: .25rem;
      }

      a {
        &:hover, &:focus {
          color: $dark;
          text-decoration: none;
        }
      }

      button {
        &:hover, &:focus {
          color: $dark;
          text-decoration: none;
        }
      }

      span {
        &:hover, &:focus {
          color: inherit;
          text-decoration: none;
        }
      }
    }
  }
}

/* Separate line b/c rules are thrown out if selector is invalid.
   IE11 and old Edge versions don't support :focus-within. */