/* ================================================
   Project: Invitewala Reseller Panel
   Author: DCPL Technology Private Limited.
=================================================== */
.small-widgets {
    padding: 24px 24px;
    gap: 12px;
    border: 1px solid transparent;
}

.small-widgets.active {
    border: 1px solid var(--purple);
}

.small-widgets .icon {
    height: 52px;
    width: 52px;
    background-color: var(--muted-seven);
}

.small-widgets .icon svg{
    height: 32px;
    width: 32px;
}

.small-widgets.active .icon {
    background-color: var(--purple-two);
}

.small-widgets.active .icon svg path {
    stroke: var(--purple) !important;
}

.small-widgets h5 {
    color: var(--black-one);
    font-size: var(--fs-24) !important;
}

.small-widgets p {
    font-size: var(--fs-19);
    line-height: 21px;
    color: var(--muted);
}

/* <------- Media Query Start -------> */
@media screen and (max-width:1920px) {
    .small-widgets {
        padding: 10px 8px;
        gap: 12px;
    }

    .small-widgets .icon {
        height: 40px;
        width: 40px;
    }

    .small-widgets .icon svg {
        height: 26px;
        width: 26px;
    }

    .small-widgets h5 {
        font-size: var(--fs-18) !important;
    }

    .small-widgets p {
        font-size: var(--fs-15);
        line-height: 21px;
    }

}

/* <------- Media Query End -------> */