/* ================================================
   Project: Invitewala Reseller Panel
   Author: DCPL Technology Private Limited.
=================================================== */

.import-file-content .import-file-header h5 {
   font-size: var(--fs-24);
   color: var(--black-one);
   line-height: 28px;
}

.import-file-content .import-file-header p {
   color: var(--muted);
   line-height: 20px;
   font-size: var(--fs-15);
}

.import-file-content .import-file-header .select-pdf-icon {
   padding: 8px 12px !important;
}

.import-file-content .import-file-header .select-pdf-icon svg {
   height: 26px;
   width: 26px;
}

.import-file-content .upload-contnt {
   border: 3px dashed var(--muted-six);
   padding: 40px;
   min-height: 280px;
}

.import-file-content .upload-contnt .upload-icon {
   height: 52px;
   width: 52px;
   background-color: var(--muted-eight);
}

.import-file-content .upload-contnt .upload-icon svg {
   height: 28px;
   width: 28px;
}

.import-file-content .upload-contnt h5 {
   font-size: var(--fs-24);
   color: var(--black-one);
   line-height: 28px;
}

.import-file-content .import-file-preview {
   min-height: 380px;
   max-width: 380px;
   border-color: var(--muted-ten) !important;
}

.import-file-content .import-file-preview .swiper-pagination {
   color: var(--black-one) !important;
   font-weight: var(--font-semibold);
   max-width: 80px !important;
   margin: 16px auto 0 auto;
   position: static;
   text-align: center;
   font-size: var(--fs-20);
}

.import-file-content .import-pdf-preview {
   min-height: 350px;
   /* max-width: 300px; */
   border-color: var(--muted-ten) !important;
}

.import-file-content .import-pdf-preview .swiper-pagination {
   color: var(--black-one) !important;
   font-weight: var(--font-semibold);
   max-width: 80px !important;
   margin: 16px auto 0 auto;
   position: static;
   text-align: center;
   font-size: var(--fs-20);
}


.import-file-content .slider-icon {
   height: 32px;
   width: 32px;
   background-color: var(--muted-ten);
   cursor: pointer;
}

.import-file-content .slider-icon.swiper-button-disabled {
   cursor: default !important;
}

.import-file-content .slider-icon:hover {
   background-color: var(--purple-two);
}

.import-file-content .slider-icon:hover svg {
   color: var(--purple);
}

/* <------- Media Query Start -------> */
@media screen and (max-width:1920px) {
   .import-file-content .import-file-header h5 {
      font-size: var(--fs-18);
      line-height: 22px;
   }

   .import-file-content .import-file-header p {
      line-height: 20px;
      font-size: var(--fs-15);
   }

   .import-file-content .import-file-header .select-pdf-icon {
      padding: 8px 8px !important;
   }

   .import-file-content .import-file-header .select-pdf-icon svg {
      height: 22px;
      width: 22px;
   }

   .import-file-content .upload-contnt {
      border: 2px dashed var(--muted-six);
      padding: 20px;
      min-height: 200px;
   }

   .import-file-content .upload-contnt .upload-icon {
      height: 40px;
      width: 40px;
   }

   .import-file-content .upload-contnt .upload-icon svg {
      height: 22px;
      width: 22px;
   }

   .import-file-content .upload-contnt h5 {
      font-size: var(--fs-18);
      line-height: 22px;
   }

   .import-file-content .import-file-preview {
      min-height: 300px;
      max-width: 300px;
   }

   .import-file-content .import-file-preview .swiper-pagination {
      font-weight: var(--font-semibold);
      max-width: 60px !important;
      margin: 16px auto 0 auto;
      font-size: var(--fs-15);
   }

   .import-file-content .import-pdf-preview {
      min-height: 300px;
   }

   .import-file-content .import-pdf-preview .swiper-pagination {
      font-weight: var(--font-semibold);
      max-width: 60px !important;
      margin: 16px auto 0 auto;
      font-size: var(--fs-15);
   }


   .import-file-content .slider-icon {
      height: 32px;
      width: 32px;
   }

}

/* <------- Media Query End -------> */