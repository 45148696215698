/* ================================================
   Project: Invitewala Reseller Panel
   Author: DCPL Technology Private Limited.

    Note: Not Change Any Style Postion in this File.
=================================================== */
.custom-step-tab {
    gap: 28px;
}

.custom-step-tab .step-tab {
    opacity: .65;
}

.custom-step-tab .step-tab .icon {
    height: 52px;
    width: 52px;
    background-color: var(--muted-eleven);
}

.custom-step-tab .step-tab .icon svg {
    height: 30px;
    width: 30px;
}

.custom-step-tab .step-tab h5 {
    color: var(--black-one);
    line-height: 28px;
    font-size: var(--fs-20);
}

.custom-step-tab .step-tab small {
    color: var(--muted);
    font-size: var(--fs-18);
    line-height: 26px;
}

.custom-step-tab .active-step,
.custom-step-tab .completed-step {
    opacity: 1 !important;
}

.custom-step-tab .active-step .icon {
    background-color: var(--purple);
}

.custom-step-tab .active-step .icon svg {
    color: var(--white);
}

.custom-step-tab .completed-step .icon {
    background-color: var(--purple-two) !important;
}

.custom-step-tab .completed-step .icon svg {
    color: var(--purple);
}

/* <------- Media Query Start -------> */
@media screen and (max-width:1920px) {
    .custom-step-tab {
        gap: 20px;
    }

    .custom-step-tab .step-tab .icon {
        height: 40px;
        width: 40px;
    }

    .custom-step-tab .step-tab .icon svg {
        height: 22px;
        width: 22px;
    }

    .custom-step-tab .step-tab h5 {
        line-height: 22px;
        font-size: var(--fs-15);
    }

    .custom-step-tab .step-tab small {
        font-size: var(--fs-13);
        line-height: 22px;
    }
}

/* <------- Media Query End -------> */