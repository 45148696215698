/* ================================================
   Project: Invitewala Reseller Panel
   Author: DCPL Technology Private Limited.
=================================================== */

aside.sidebar {
    width: 340px;
    min-width: 340px;
    max-width: 340px;
    box-shadow: 0 .125rem .5rem 0 var(--muted-twelve);
}

aside.sidebar .site-logo {
    height: 85px;
    padding: 0 22px 0 28px;
}

aside.sidebar .site-logo img {
    height: 60px;
}

aside.sidebar .effect-logo::after {
    content: '';
    position: absolute;
    top: 76px;
    left: 0;
    right: 0;
    width: 100%;
    pointer-events: none;
    z-index: 2;
    background: linear-gradient(var(--white)41%, var(--white-two) 95%, rgba(255, 255, 255, 0));
    height: 85px;
}

aside.sidebar .sidebar-item {
    margin-top: 10px;
    font-size: var(--fs-20)
}

aside.sidebar .sidebar-item a {
    color: var(--black-one);
    border-radius: 6px;
    margin: 0 22px;
    padding: 12px 22px;
    gap: 16px;
}

aside.sidebar .sidebar-item a svg {
    height: 24px;
    width: 24px;
}

aside.sidebar .sidebar-item a:hover {
    background-color: var(--muted-eleven);
}

aside.sidebar .sidebar-item a.active {
    background: linear-gradient(270deg, var(--purple-three) 0%, var(--purple) 100%);
    box-shadow: 0px 2px 6px 0px var(--purple-four);
    color: var(--white);
}

aside.sidebar .sidebar-item a.active svg {
    color: var(--white);
}

/* <------- Media Query Start -------> */
@media screen and (max-width:1920px) {
    aside.sidebar {
        width: 260px;
        min-width: 260px;
        max-width: 260px;
    }

    aside.sidebar .site-logo {
        height: 64px;
        padding: 0 16px 0 22px;
    }

    aside.sidebar .site-logo img {
        height: 44px;
    }

    aside.sidebar .effect-logo::after {
        top: 56px;
        height: 48px;
    }

    aside.sidebar .sidebar-item {
        margin-top: 6px;
        font-size: var(--fs-15)
    }

    aside.sidebar .sidebar-item a {
        margin: 0 12px;
        padding: 8px 12px;
        gap: 8px;
    }

    aside.sidebar .sidebar-item a svg {
        height: 18px;
        width: 18px;
    }
}

/* <------- Media Query End -------> */