/* ================================================
   Project: Invitewala Reseller Panel
   Author: DCPL Technology Private Limited.
=================================================== */
.common-popup-backdrop {
    background-color: var(--white-five) !important;
    opacity: 0.4 !important;
}

.common-popup-dialog {
    border-radius: 8px !important;
}

.common-popup-dialog-size {
    --bs-modal-width: 600px;
}

.common-popup-dialog .common-popup-content {
    padding: 40px;
    border-radius: 8px !important;
    position: relative !important;
    border: none !important;
    box-shadow: 0 .25rem 1.125rem 0 var(--muted-eighteen);
}

.common-popup-dialog .common-popup-content .common-popup-body {
    padding: 24px;
    position: static;
}

.common-popup-dialog .common-popup-content .common-popup-body .common-popup-colose-btn {
    height: 45px;
    width: 45px;
    transition: all .23s ease .1s;
    box-shadow: 0 .0625rem .375rem 0 var(--muted-twentyeight);
    top: -19px;
    right: -15px !important;
}

.common-popup-dialog .common-popup-content .common-popup-body .common-popup-colose-btn svg {
    height: 22px;
    width: 22px;
}

.common-popup-dialog .common-popup-content .common-popup-body .common-popup-colose-btn:hover {
    transition: all .23s ease .1s;
    top: -7px;
    right: -2px !important;
}


.common-popup-dialog .common-popup-content .common-popup-body .common-popup-heading h4 {
    font-size: var(--fs-32);
    line-height: 42px;
    color: var(--blacl-one);
}

.common-popup-dialog .common-popup-content .common-popup-body .common-popup-heading p {
    color: var(--muted);
    line-height: 24px;
    font-size: var(--fs-20);
}

.common-popup-dialog .common-popup-content .common-popup-body .common-popup-btn button {
    min-width: 92px;
}


/* <------- Media Query Start -------> */
@media screen and (max-width:1920px) {

    .common-popup-dialog-size {
        --bs-modal-width: 500px;
    }

    .common-popup-dialog .common-popup-content {
        padding: 48px;
    }


    .common-popup-dialog .common-popup-content .common-popup-body {
        padding: 24px;
    }

    .common-popup-dialog .common-popup-content .common-popup-body .common-popup-colose-btn {
        height: 30px;
        width: 30px;
        top: -10px;
        right: -7px !important;
    }

    .common-popup-dialog .common-popup-content .common-popup-body .common-popup-colose-btn svg {
        height: 16px;
        width: 16px;
    }

    .common-popup-dialog .common-popup-content .common-popup-body .common-popup-colose-btn:hover {
        top: -7px;
        right: -2px !important;
    }


    .common-popup-dialog .common-popup-content .common-popup-body .common-popup-heading h4 {
        font-size: var(--fs-24);
        line-height: 38px;
    }

    .common-popup-dialog .common-popup-content .common-popup-body .common-popup-heading p {
        line-height: 20px;
        font-size: var(--fs-15);
    }

    .common-popup-dialog .common-popup-content .common-popup-body .common-popup-btn button {
        min-width: 92px;
    }
}

@media screen and (max-width:1399px) {
    .common-popup-dialog .common-popup-content {
        padding: 32px;
    }
}

@media screen and (max-width:1199px) {}

@media screen and (max-width:991px) {}

@media screen and (max-width:767px) {}

/* <------- Media Query End -------> */