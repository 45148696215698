.sidebar-credit-warning-container {
  margin: 5px 15px 0px 15px;
  color: #d9534f;
  background-color: #fdecec;
  padding: 6px 12px;
  border-radius: 4px;
  font-weight: bold;
  font-size: 14px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 300px;
}
