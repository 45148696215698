/* ================================================
   Project: Invitewala Reseller Panel
   Author: DCPL Technology Private Limited.
=================================================== */
.pg-content {
    height: 100%;
    width: calc(100% + -340px);
    margin-left: 340px;
}


.pg-content::before {
    content: "";
    width: 100%;
    height: 4.5rem;
    position: fixed;
    top: 0px;
    z-index: 10;
    backdrop-filter: saturate(200%) blur(10px);
    background: linear-gradient(180deg, var(--muted-thirdteen) 44%, var(--muted-fourteen) 73%, var(--muted-fifthteen));
    mask: linear-gradient(var(--white-one), var(--white-one) 18%, transparent 100%);
}

.pg-content .pg-container {
    max-width: calc(2100px - 24px* 2);
}

.pg-content .pg-container .pg-mian-content {
    padding: 28px 0;
    margin-top: 32px;
}

/* <------- Media Query Start -------> */
@media screen and (max-width:1920px) {

    .pg-content {
        width: calc(100% + -260px);
        margin-left: 260px;
    }

    .pg-content .pg-container {
        max-width: calc(1440px - 24px* 2);

    }

    .pg-content .pg-container .pg-mian-content {
        padding: 24px 0;
        margin-top: 16px;
    }
}

/* <------- Media Query End -------> */