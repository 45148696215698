/* ================================================
   Project: Invitewala Reseller Panel
   Author: DCPL Technology Private Limited.
=================================================== */

.custome-selectbox {
    padding: 10px 44px 10px 16px !important;
    font-size: var(--fs-19) !important;
    border-radius: 6px !important;
    outline: 1px solid transparent !important;
    color: var(--black-one) !important;
}

.custome-selectbox:focus {
    box-shadow: none !important;
    border-color: var(--purple) !important;
    outline-color: var(--purple) !important;
}

/* <------- Media Query Start -------> */
@media screen and (max-width:1920px) {
    .custome-selectbox {
        padding: 6px 44px 6px 14px !important;
        font-size: var(--fs-15) !important;
    }

}

/* <------- Media Query End -------> */