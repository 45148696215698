/* ================================================
   Project: Invitewala Reseller Panel
   Author: DCPL Technology Private Limited.
=================================================== */

.custom-radio-btn input:checked {
    background-color: var(--purple) !important;
    border-color: var(--purple) !important;
}

.custom-radio-btn input:focus {
    box-shadow: none !important;
}

.sm-radio input {
    height: 16px !important;
    width: 16px !important;
}

.md-radio input {
    height: 24px !important;
    width: 24px !important;
}

.lg-radio input {
    height: 20px !important;
    width: 20px !important;
}

.xl-radio input {
    height: 22px !important;
    width: 22px !important;
}

.xxl-radio input {
    height: 24px !important;
    width: 24px !important;
}

/* <------- Media Query Start -------> */
@media screen and (max-width:1920px) {

    .sm-radio input {
        height: 16px !important;
        width: 16px !important;
    }

    .md-radio input {
        height: 18px !important;
        width: 18px !important;
    }

    .lg-radio input {
        height: 20px !important;
        width: 20px !important;
    }

    .xl-radio input {
        height: 22px !important;
        width: 22px !important;
    }

    .xxl-radio input {
        height: 24px !important;
        width: 24px !important;
    }
}

/* <------- Media Query End -------> */