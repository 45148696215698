/* ================================================
   Project: Invitewala Reseller Panel
   Author: DCPL Technology Private Limited.
=================================================== */

.common-input {
    padding: 8px 20px !important;
    font-size: var(--fs-20) !important;
    color: var(--black-one);
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    background-clip: padding-box;
    border-color: var(--muted-twentyone) !important;
    min-height: 52px;
    vertical-align: middle !important;
}

.common-input[type="search"]::-webkit-search-cancel-button {
    display: none;
}

.common-input:hover {
    border-color: var(--muted-twenty) !important;
    outline-color: var(--muted-twenty) !important;
}

.common-input:focus {
    border-color: var(--purple) !important;
    box-shadow: 0 2px 6px 0 var(--purple-four) !important;
    color: var(--black-one);
    border-width: 2px;
    padding: calc(.426rem - 2px) calc(.9375rem - 2px);
    transition: all .15s;
}

.common-input::placeholder {
    font-size: var(--fs-20) !important;
    color: var(--muted) !important;
    font-weight: var(--font-normal) !important;
    transition: all .15s;
    vertical-align: middle !important;
}

.common-input:focus::placeholder {
    transform: translateX(3px);
    transition: all .15s;
}

.common-input.error {
    border-width: 2px;
    border-color: var(--red) !important;
}

input::-webkit-calendar-picker-indicator {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCIgd2lkdGg9IjQ4IiBoZWlnaHQ9IjQ4IiBjb2xvcj0iIzQ0NDA1MCIgZmlsbD0ibm9uZSI+CiAgICA8cGF0aCBkPSJNMTggMlY0TTYgMlY0IiBzdHJva2U9ImN1cnJlbnRDb2xvciIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgLz4KICAgIDxwYXRoIGQ9Ik0xMS45OTU1IDEzSDEyLjAwNDVNMTEuOTk1NSAxN0gxMi4wMDQ1TTE1Ljk5MSAxM0gxNk04IDEzSDguMDA4OTdNOCAxN0g4LjAwODk3IiBzdHJva2U9ImN1cnJlbnRDb2xvciIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIC8+CiAgICA8cGF0aCBkPSJNMy41IDhIMjAuNSIgc3Ryb2tlPSJjdXJyZW50Q29sb3IiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIC8+CiAgICA8cGF0aCBkPSJNMi41IDEyLjI0MzJDMi41IDcuODg1OTQgMi41IDUuNzA3MjggMy43NTIxMiA0LjM1MzY0QzUuMDA0MjQgMyA3LjAxOTQ5IDMgMTEuMDUgM0gxMi45NUMxNi45ODA1IDMgMTguOTk1OCAzIDIwLjI0NzkgNC4zNTM2NEMyMS41IDUuNzA3MjggMjEuNSA3Ljg4NTk0IDIxLjUgMTIuMjQzMlYxMi43NTY4QzIxLjUgMTcuMTE0MSAyMS41IDE5LjI5MjcgMjAuMjQ3OSAyMC42NDY0QzE4Ljk5NTggMjIgMTYuOTgwNSAyMiAxMi45NSAyMkgxMS4wNUM3LjAxOTQ5IDIyIDUuMDA0MjQgMjIgMy43NTIxMiAyMC42NDY0QzIuNSAxOS4yOTI3IDIuNSAxNy4xMTQxIDIuNSAxMi43NTY4VjEyLjI0MzJaIiBzdHJva2U9ImN1cnJlbnRDb2xvciIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgLz4KICAgIDxwYXRoIGQ9Ik0zIDhIMjEiIHN0cm9rZT0iY3VycmVudENvbG9yIiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiAvPgo8L3N2Zz4=)
}

svg.error {
    color: var(--red) !important;
}

.password-input svg {
    height: 24px;
    width: 24px;
}

/* <------- Media Query Start -------> */
@media screen and (max-width:1920px) {
    .common-input {
        padding: 6px 14px !important;
        font-size: var(--fs-15) !important;
        line-height: 1.625;
        min-height: 40px;
    }

    .common-input::placeholder {
        font-size: var(--fs-15) !important;
    }

    .password-input svg {
        height: 20px;
        width: 20px;
    }

}

/* <------- Media Query End -------> */