.tablecontent_details {
    border: 1px solid var(--black-color-opacity-100);
    padding: 24px;
}
.tablecontent_details h6 {
    font-size: 20px;
    color: var(--gray-blue-color-800);
}
.floating-label-content {
    margin-bottom: 32px;
}
.floating-label {
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 0.5rem;
    /* pointer-events: none;
    left: 22px;
    top: 12px;
    padding: 0 5px; */
    /* transition: top 0.3s ease, font-size 0.3s ease, color 0.3s ease; */
}
.floating-input {
    transition: border-color 0.3s ease;
    height: 48px;
    padding: 0 20px;
    color: var(--gray-blue-color-800);
    border: 1px solid var(--gray-blue-color-600);
    border-radius: 4px !important;
}
.floating-input:focus{
    color: var(--bs-body-color);
    background-color: var(--bs-body-bg);
    border-color: #b1a5ff;
    outline: 0;
    box-shadow: 0 0 0 .25rem rgba(98, 75, 255, .25);
}

/* .floating-input:focus ~ .floating-label {
    top: -8px;
    font-size: 13px;
} */
/* .floating-label-content input:focus + label {
    color: var(--primary-color-500) !important;
} */
/* .floating-label-content input:not(:placeholder-shown) + label {
    top: -9px;
    font-size: 12px;
    left: 25px;
    color: var(--gray-blue-color-900);
} */
/* .floating-label-content .required_alert {
    color: var(--alert-color);
} */
.floating-input:focus {
    background-color: white;
}
.top_title .subhead_title {
    font-size: 38px;
    font-weight: 600;
}
.top_title {
    padding: 0px 55px;
}