/* ================================================
   Project: Invitewala Reseller Panel
   Author: DCPL Technology Private Limited.
=================================================== */

.back-page-tab {
    gap: 10px;
}

.back-page-tab svg {
    height: 24px;
    width: 24px;
}


.back-page-tab h2 {
    font-size: var(--fs-24) !important;
    color: var(--black-one) !important;
    line-height: 28px !important;
}

/* <------- Media Quert Start -------> */
@media screen and (max-width:1920px) {

    .back-page-tab {
        gap: 4px;
    }

    .back-page-tab svg {
        height: 22px;
        width: 22px;
    }

    .back-page-tab h2 {
        font-size: var(--fs-18) !important;
        line-height: 22px !important;
    }
}

/* <------- Media Quert End -------> */