/* ================================================
   Project: Invitewala Reseller Panel
   Author: DCPL Technology Private Limited.
=================================================== */

.send-invitaion-left-content h5 {
    font-size: var(--fs-24) !important;
    line-height: 28px !important;
    color: var(--black-one) !important;
}

.send-invitaion-left-content p {
    color: var(--black-one);
    line-height: 28px;
    font-size: var(--fs-20);
}

.send-invitaion-left-content .custom-color-picker {
    padding-right: 50px !important;

}

.send-invitaion-right-content h5 {
    font-size: var(--fs-24) !important;
    line-height: 28px !important;
    color: var(--black-one) !important;
}


.send-invitaion-right-content .pdf-preview-content .konvajs-content {
    position: static !important;
    height: 100% !important;
    width: 100% !important;

}

.send-invitaion-right-content .pdf-preview-content canvas {
    position: static !important;
    /* height: 100% !important;
    width: 100% !important; */
    width: 752px !important;
    height: 731px !important;
    object-fit: cover !important;
    border: 1px solid var(--muted-six) !important;
}

.send-invitaion-right-content .img-view-content {
    /* max-height: 450px; */
    /* width: 100px !important; */
    /* overflow-y: auto; */
    height: 550px;
    /* width: 220px; */
}

.send-invitaion-right-content .img-view-content .img-view {
    border: 1px solid var(--muted-six);
}

.img-view-content .highlighted {
    padding: 5px;
    border: 2px solid var(--purple) !important;
    border-radius: 2px;
    box-shadow: 0 0 21px rgba(98, 88, 204, 0.5);
    height: 160px;
    width: 120px;
}

.allow-duplicates-contnt {
    max-width: 600px;
    padding: 8px 12px;
    background-color: var(--purple-two);
}

.allow-duplicates-contnt p {
    color: var(--black-one);
    line-height: 20px;
}

/* <------- Media Query Start  -------> */
@media screen and (max-width:1920px) {
    .send-invitaion-left-content h5 {
        font-size: var(--fs-18) !important;
        line-height: 22px !important;
    }

    .send-invitaion-left-content p {
        line-height: 20px;
        font-size: var(--fs-15);
    }

    .send-invitaion-left-content .custom-color-picker {
        padding-right: 50px !important;
    }

    .send-invitaion-right-content h5 {
        font-size: var(--fs-18) !important;
        line-height: 22px !important;
    }

    .send-invitaion-right-content .pdf-preview-content canvas {
        position: static !important;
        height: 100% !important;
        width: 100% !important;
    }

    .send-invitaion-right-content .img-view-content {
        height: 450px;
    }

    .allow-duplicates-contnt {
        max-width: 600px;
        padding: 8px 12px;
    }

    .allow-duplicates-contnt p {
        line-height: 20px;
    }

    .send-invitaion-right-content .img-view-content .img-view {
        height: 132px;
        width: 100px;
    }
}

/* <------- Media Query End  -------> */