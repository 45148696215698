/* ================================================
   Project: Invitewala Reseller Panel
   Author: DCPL Technology Private Limited.
=================================================== */
.custom-checkbox input {
    margin-top: 0px;
}

.custom-checkbox input:checked {
    background-color: var(--purple) !important;
    border-color: var(--purple) !important;
}


.custom-checkbox input:focus {
    box-shadow: none !important;
}

.sm-checkbox input {
    height: 16px !important;
    width: 16px !important;
    cursor:pointer;
}

.md-checkbox input {
    height: 18px !important;
    width: 18px !important;
}

.lg-checkbox input {
    height: 20px !important;
    width: 20px !important;
}

.xl-checkbox input {
    height: 22px !important;
    width: 22px !important;
}

.xxl-checkbox input {
    height: 24px !important;
    width: 24px !important;
}