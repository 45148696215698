/* ================================================
   Project: Invitewala Reseller Panel
   Author: DCPL Technology Private Limited.
=================================================== */



:root {
    /* 01. Font Sizes variables */
    --fs-10: 10px;
    --fs-11: 11px;
    --fs-12: 12px;
    --fs-13: 13px;
    --fs-14: 14px;
    --fs-15: 15px;
    --fs-16: 16px;
    --fs-17: 17px;
    --fs-18: 18px;
    --fs-19: 19px;
    --fs-20: 20px;
    --fs-21: 21px;
    --fs-22: 22px;
    --fs-22: 22px;
    --fs-23: 23px;
    --fs-24: 24px;
    --fs-25: 25px;
    --fs-26: 26px;
    --fs-27: 27px;
    --fs-28: 28px;
    --fs-29: 29px;
    --fs-30: 30px;
    --fs-31: 31px;
    --fs-32: 32px;
    --fs-32: 32px;
    --fs-33: 33px;
    --fs-34: 34px;
    --fs-35: 35px;
    --fs-36: 36px;
    --fs-37: 37px;
    --fs-38: 38px;
    --fs-39: 39px;
    --fs-40: 40px;
    --fs-41: 41px;
    --fs-42: 42px;
    --fs-42: 42px;
    --fs-43: 43px;
    --fs-44: 44px;
    --fs-45: 45px;
    --fs-46: 46px;
    --fs-47: 47px;
    --fs-48: 48px;
    --fs-49: 49px;
    --fs-50: 50px;
    --fs-51: 51px;
    --fs-52: 52px;
    --fs-52: 52px;
    --fs-53: 53px;
    --fs-54: 54px;
    --fs-55: 55px;
    --fs-56: 56px;
    --fs-57: 57px;
    --fs-58: 58px;
    --fs-59: 59px;
    --fs-60: 60px;
    --fs-61: 61px;
    --fs-62: 62px;
    --fs-62: 62px;
    --fs-63: 63px;
    --fs-64: 64px;
    --fs-65: 65px;
    --fs-66: 66px;
    --fs-67: 67px;
    --fs-68: 68px;
    --fs-69: 69px;
    --fs-70: 70px;
    --fs-71: 71px;
    --fs-72: 72px;
    --fs-72: 72px;
    --fs-73: 73px;
    --fs-74: 74px;
    --fs-75: 75px;
    --fs-76: 76px;
    --fs-77: 77px;
    --fs-78: 78px;
    --fs-79: 79px;
    --fs-80: 80px;
    --fs-81: 81px;
    --fs-82: 82px;
    --fs-82: 82px;
    --fs-83: 83px;
    --fs-84: 84px;
    --fs-85: 85px;
    --fs-86: 86px;
    --fs-87: 87px;
    --fs-88: 88px;
    --fs-89: 89px;
    --fs-90: 90px;

    /* 02. Font Weight variables */
    --font-thin: 100;
    --font-extralight: 200;
    --font-light: 300;
    --font-normal: 400;
    --font-medium: 500;
    --font-semibold: 600;
    --font-bold: 700;
    --font-extrabold: 800;
    --font-black: 900;
}