/* ================================================
   Project: Invitewala Reseller Panel
   Author: DCPL Technology Private Limited.
=================================================== */

.widget-content {
    border-right: 1px solid var(--muted-six);
}

.widget-content h4 {
    font-size: var(--fs-34);
    color: var(--black-one);
    line-height: 46px;
}

.widget-content p {
    color: var(--muted);
    font-size: var(--fs-20);
    line-height: 30px
}

.widget-content .widget-iconbox {
    height: 52px;
    width: 52px;
    background-color: var(--muted-seven);
}

.widget-content .widget-iconbox svg {
    height: 34px;
    width: 34px;
}


/* <------- Media Query Start -------> */

@media screen and (max-width:1920px) {
    .widget-content h4 {
        font-size: var(--fs-24);
        line-height: 42px;
    }

    .widget-content p {
        font-size: var(--fs-15);
        line-height: 21px
    }

    .widget-content .widget-iconbox {
        height: 40px;
        width: 40px;
    }

    .widget-content .widget-iconbox svg {
        height: 26px;
        width: 26px;
    }
}


@media screen and (max-width:1199px) {
    .widget-content h4 {
        font-size: var(--fs-23);
        line-height: 38px;
    }

    .widget-content p {
        line-height: 20px
    }
}

@media screen and (max-width:1199px) {
    .widget-content h4 {
        font-size: var(--fs-23);
        line-height: 38px;
    }

    .widget-content p {
        line-height: 20px
    }
}

@media screen and (max-width:849px) {
    .widget-content h4 {
        font-size: var(--fs-22);
        line-height: 38px;
    }
}

@media screen and (max-width:575px) {
    .widget-content h4 {
        font-size: var(--fs-21);
        line-height: 38px;
    }
}

/* <------- Media Query End -------> */