/* ================================================
   Project: Invitewala Reseller Panel
   Author: DCPL Technology Private Limited.
=================================================== */


.import-number-content .import-number-header h5 {
    font-size: var(--fs-24);
    color: var(--black-one);
    line-height: 28px;
}

.import-number-content .import-number-header p {
    color: var(--muted);
    line-height: 26px;
    font-size: var(--fs-20);
}

.import-number-content .reiview-duplicates-content h5 {
    font-size: var(--fs-24);
    color: var(--black-one);
    line-height: 28px;
}

.import-number-content .reiview-duplicates-content span {
    height: 16px;
    width: 16px;
    margin-top: 6px;
}

.import-number-content .reiview-duplicates-content p {
    color: var(--muted);
    font-size: var(--fs-19);
}

.import-number-content .allow-duplicates-contnt {
    max-width: 700px;
    padding: 14px 20px;
    background-color: var(--purple-two);
}

.import-number-content .allow-duplicates-contnt input {
    height: 22px;
    width: 22px;
}


.import-number-content .allow-duplicates-contnt p {
    color: var(--black-one);
    font-size: var(--fs-20);
    line-height: 24px;
}

.import-number-content-select .custom-select .css-1f43avz-a11yText-A11yText,
.import-number-content-select .custom-select .css-1f43avz-a11yText-A11yText {
    display: none !important;
}

.import-number-content-select .custom-select {
    height: 48px;
    font-size: var(--fs-20);
}

.import-from-file .custom-select .css-13cymwt-control {
    height: 48px !important;
}

.bg-dot-purple {
    background-color: var(--purple) !important;
}

.bg-dot-red {
    background-color: var(--red) !important;
}

.v-divider {
    height: 33px;
    width: 1px;
    background-color: var(--muted-twentyone) !important;
}

.manual-import-content small {
    height: 10px;
    width: 10px;
    background-color: var(--black-one);
}

.manual-import-content p {
    font-size: var(--fs-19);
}

.import-from-file {
    border: 3px dashed var(--muted-six);
    border-radius: 6px;
    padding: 65px 24px 65px 24px;
}

.import-from-file h4 {
    color: var(--black-one);
    font-size: var(--fs-28);
    line-height: 32px;
}

.import-from-file a {
    color: var(--purple) !important;
    font-size: var(--fs-19) !important;
}

.import-from-file .icon {
    height: 52px;
    width: 52px;
    background-color: var(--muted-eight);
}

.import-from-file .icon svg {
    height: 28px;
    width: 28px;
}

/* <------- Media Query Start --------> */
@media screen and (max-width:1920px) {
    .import-number-content .import-number-header h5 {
        font-size: var(--fs-18);
        line-height: 22px;
    }

    .import-number-content .import-number-header p {
        line-height: 20px;
        font-size: var(--fs-15);
    }

    .import-number-content .reiview-duplicates-content h5 {
        font-size: var(--fs-18);
        line-height: 22px;
    }

    .import-number-content .reiview-duplicates-content span {
        height: 12px;
        width: 12px;
        margin-top: 6px;
    }

    .import-number-content .reiview-duplicates-content p {
        font-size: var(--fs-15);
    }

    .import-number-content .allow-duplicates-contnt {
        max-width: 600px;
        padding: 8px 12px;
    }

    .import-number-content .allow-duplicates-contnt input {
        height: 18px;
        width: 18px;
    }

    .import-number-content .allow-duplicates-contnt p {
        line-height: 20px;
        font-size: var(--fs-15);
    }


    .v-divider {
        height: 33px;
        width: 1px;
    }

    .manual-import-content small {
        height: 7px;
        width: 7px;

    }

    .manual-import-content p {
        font-size: var(--fs-15);
    }

    .import-from-file {
        border: 2px dashed var(--muted-six);
        padding: 70px 24px 48px 24px;
    }

    .import-from-file h4 {
        font-size: var(--fs-24);
        line-height: 27px;
    }

    .import-from-file a {
        font-size: var(--fs-15) !important;
    }

    .import-from-file .icon {
        height: 40px;
        width: 40px;
    }

    .import-from-file .icon svg {
        height: 20px;
        width: 20px;
    }

    .import-number-content-select .custom-select {
        height: 38px;
        font-size: var(--fs-15);
    }

    .import-from-file .custom-select .css-13cymwt-control {
        height: 38px !important;
    }

}

/* <------- Media Query End --------> */