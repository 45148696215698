/* ================================================
   Project: Invitewala Reseller Panel
   Author: DCPL Technology Private Limited.
=================================================== */

.baisic-widgets {
    padding: 16px 24px;
}

.baisic-widgets p {
    font-size: var(--fs-20) !important;
    color: var(--muted) !important;
}

.baisic-widgets h5 {
    font-size: var(--fs-24) !important;
    color: var(--black-one) !important;
}

.baisic-widgets .icon {
    bottom: 12px;
    right: 12px;
}

/* <------- Media Query Start -------> */

@media screen and (max-width:1920px) {
    .baisic-widgets {
        padding: 16px 24px;
    }

    .baisic-widgets p {
        font-size: var(--fs-17) !important;
    }

    .baisic-widgets h5 {
        font-size: var(--fs-20) !important;
    }

    .baisic-widgets .icon {
        bottom: 8px;
        right: 8px;
    }
}

@media screen and (max-width:1500px) {
    .baisic-widgets {
        padding: 16px 16px;
    }

}

@media screen and (max-width:1399px) {
    .baisic-widgets {
        padding: 16px 24px;
    }

}

/* <------- Media Query End -------> */