/* .animated-text {
    animation: slideIn 3s infinite alternate;
    font-weight: bold;
    font-size: 16px;
  }
  
  @keyframes slideIn {
    0% {
      transform: translateX(0);
    }
    50% {
      transform: translateX(10px);
    }
    100% {
      transform: translateX(0);
    }
  }
   */

.scrolling-text-container {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  position: relative;
}

.scrolling-text {
  display: inline-block;
  animation: scrollText 7s linear infinite;
  font-weight: bold;
  color: red !important;
  font-size: 16px;
}

@keyframes scrollText {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-100%);
  }
}