.cerdit-logs-content {
    .cedit-h-card {
        background-color: $gray-100;

        p {
            font-weight: 400;
            color: $gray-600;
            font-size: 16px;
            margin-bottom: 2px !important;
        }

        span {
            font-size: 20px !important;
            font-weight: 500 !important;
            color: $gray-900 !important;
        }

        // .credit-h-icon {
        //     height: 36px;
        //     width: 36px;
        //     background-color: $extra-light-primary;
        // }
    }
}