/* ================================================
   Project: Invitewala Reseller Panel
   Author: DCPL Technology Private Limited.
=================================================== */

.organization-list-content .organization-widget .hr-line {
    border-top: 1px solid var(--muted-six);
}

.organization-list-content .organization-widget:last-child .widget-content {
    border-right: none !important;
}

.organization-list-content .organization-widget:last-child .widget-content .widget-iconbox {
    margin-right: 0 !important;
}


/* <------- Media Query Start -------> */
@media screen and (max-width:1920px) {}




@media screen and (max-width:1199px) {
    .organization-list-content .organization-widget:last-child .widget-content .widget-iconbox {
        margin-right: 24px !important;
    }

    .organization-list-content .organization-widget:nth-of-type(2) .widget-content {
        border-right: none !important;
    }

    .organization-list-content .organization-widget:nth-of-type(2) .widget-content .widget-iconbox {
        margin-right: 0 !important;
    }

}

@media screen and (max-width:991px) {

    .organization-list-content .organization-widget:first-child .widget-content {
        border-right: none !important;
    }

    .organization-list-content .organization-widget:first-child .widget-content .widget-iconbox {
        margin-right: 0 !important;
    }

    .organization-list-content .organization-widget:last-child .widget-content .widget-iconbox {
        margin-right: 0 !important;
        margin-right: 0 !important;
    }

}

/* <------- Media Query End -------> */