/* ================================================
   Project: Invitewala Reseller Panel
   Author: DCPL Technology Private Limited.

    Note: Not Change Any CSS Postion in this File.
=================================================== */
.table-title {
    font-size: var(--fs-22) !important;
    line-height: 22px !important;
    color: var(--black-one) !important;
}

.responsive-tab-height {
    max-height: 600px;
}

.common-table {
    margin-bottom: 0px !important;
}

/* .common-table thead tr {
    border-top: 1px solid var(--muted-six) !important;
    border-bottom: 1px solid var(--muted-six) !important;
} */

.common-table thead tr th {
    padding: 0;
    color: var(--black-one) !important;
    font-weight: var(--font-medium) !important;
    font-size: var(--fs-17) !important;
    text-transform: uppercase !important;
    white-space: nowrap !important;
    border: none !important;
}

.common-table thead tr th .table-br {
    padding: 24px 32px 24px 28px !important;
    border-top: 1px solid var(--muted-six) !important;
    border-bottom: 1px solid var(--muted-six) !important;
    height: 100%
}

.common-table thead tr th.sorting {
    position: relative !important;
    cursor: pointer !important;
}

.common-table thead tr th.sorting::before {
    content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUiIGhlaWdodD0iMTUiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE4IDE1TDEyIDlMNiAxNSIgc3Ryb2tlPSIjMTQxQjM0IiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLW1pdGVybGltaXQ9IjE2IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==");
    position: absolute;
    width: 18px;
    height: 18px;
    right: 18px !important;
    line-height: 36px !important;
    top: 14px;
    visibility: hidden;
    opacity: .6;
}

.common-table thead tr th.sorting::after {
    content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUiIGhlaWdodD0iMTUiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTUuOTk5NzcgOS4wMDAwNUwxMS45OTk4IDE1TDE3Ljk5OTggOSIgc3Ryb2tlPSIjMTQxQjM0IiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLW1pdGVybGltaXQ9IjE2IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==");
    position: absolute;
    width: 18px;
    height: 18px;
    right: 18px !important;
    line-height: 36px !important;
    bottom: 18px;
    visibility: hidden;
    opacity: .6;
}

.common-table thead tr th.sorting:hover::before,
.common-table thead tr th.sorting:hover::after {
    visibility: visible;
}


.common-table thead tr th p {
    color: var(--black-one) !important;
    font-weight: var(--font-medium) !important;
    font-size: var(--fs-17) !important;
    text-transform: uppercase !important;
    white-space: nowrap !important
}


.common-table tbody tr {
    border-top: 1px solid var(--muted-six) !important;
    border-bottom: 1px solid var(--muted-six) !important;
}

.common-table tbody tr.border-none-force {
    border-bottom: none !important;
}

.common-table tbody tr:first-child {
    border-top: none !important;
}

.common-table tbody tr:last-child {
    border-bottom: none !important;
}


.common-table tbody tr td {
    padding: 18px 28px !important;
    color: var(--muted) !important;
    font-weight: var(--font-normal);
    font-size: var(--fs-20) !important;
    white-space: nowrap !important;
    vertical-align: middle !important;
    border: none;
}

.common-table tbody tr td .table-icon {
    height: 21px;
    width: 21px;
}

.common-table tbody tr td .profile-icon {
    height: 48px;
    width: 48px;
    overflow: hidden;
    border-radius: 50%;
    text-transform: uppercase;
    font-size: var(--fs-18);
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 1px;
    cursor: pointer;
}


.common-table tbody tr:nth-child(6n-5) td .profile-icon {
    background-color: var(--purple-two);
    color: var(--purple);
}

.common-table tbody tr:nth-child(6n-4) td .profile-icon {
    background-color: var(--pink);
    color: var(--red);
}

.common-table tbody tr:nth-child(6n-3) td .profile-icon {
    background-color: var(--green-one);
    color: var(--green);
}

.common-table tbody tr:nth-child(6n-2) td .profile-icon {
    background-color: var(--orange-one);
    color: var(--orange);
}

.common-table tbody tr:nth-child(6n-1) td .profile-icon {
    background-color: var(--cyan);
    color: var(--dark-skyblue);
}

.common-table tbody tr:nth-child(6n-0) td .profile-icon {
    background-color: var(--muted-twentytwo);
    color: var(--muted-twentythree);
}

.common-table tbody tr td a {
    text-decoration: none !important;
    line-height: 26px;
}

.common-table tbody tr td.id {
    color: var(--purple) !important;
}

.common-table tbody tr td a p {
    font-size: var(--fs-20);
}

.common-table tbody tr td a p,
.common-table tbody tr td p {
    color: var(--black-one);
    line-height: 26px;
}

.common-table tbody tr td a:hover p,
.common-table tbody tr td a:hover {
    color: var(--purple-five) !important;
}

.common-table tbody tr td small {
    color: var(--muted) !important;
    font-size: var(--fs-18) !important;
}

.common-table tbody tr td.table-check input,
.common-table thead tr th.table-check input {
    height: 20px !important;
    width: 20px !important;
}

.common-table tbody tr td.table-svg svg {
    height: 20px;
    width: 20px;
}

.common-table tfoot tr {
    border-top: 1px solid var(--muted-six);
    border-bottom: 1px solid var(--muted-six);
}

.common-table tfoot tr td {
    padding: 24px 32px 24px 28px !important;
    color: var(--black-one) !important;
    font-weight: var(--font-medium) !important;
    font-size: var(--fs-17) !important;
    text-transform: uppercase !important;
    white-space: nowrap !important;
    border: none !important;
}


.common-table tfoot tr td p {
    color: var(--black-one) !important;
    font-weight: var(--font-medium) !important;
    font-size: var(--fs-17) !important;
    text-transform: uppercase !important;
    white-space: nowrap !important
}

.common-table .blank-table-view {
    padding: 160px 20px;
    max-width: 550px;
    margin: 0 auto;
}

.common-table .blank-table-view h5 {
    font-size: var(--fs-22) !important;
    line-height: 28px !important;
    color: var(--black-one) !important;
}

.common-table .blank-table-view p {
    font-size: var(--fs-19) !important;
    color: var(--muted) !important;
    line-height: 28px !important;
    white-space: wrap;

}

.custom-table-infotext {
    color: var(--muted-four);
    font-size: var(--fs-20);
}

/* <------- Media Query Start -------> */
@media screen and (max-width:1920px) {
    .table-title {
        font-size: var(--fs-18) !important;
        line-height: 22px !important;
    }

    .responsive-tab-height {
        max-height: 400px;
    }

    .common-table thead tr th {
        font-size: var(--fs-13) !important;
    }

    .common-table thead tr th .table-br {
        padding: 19px 26px 19px 20px !important;
    }

    .common-table thead tr th.sorting::before {
        content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUiIGhlaWdodD0iMTUiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE4IDE1TDEyIDlMNiAxNSIgc3Ryb2tlPSIjMTQxQjM0IiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLW1pdGVybGltaXQ9IjE2IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==");
        width: 10px;
        height: 10px;
        right: 10px !important;
        line-height: 20px !important;
        top: 14px;
    }

    .common-table thead tr th.sorting::after {
        content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUiIGhlaWdodD0iMTUiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTUuOTk5NzcgOS4wMDAwNUwxMS45OTk4IDE1TDE3Ljk5OTggOSIgc3Ryb2tlPSIjMTQxQjM0IiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLW1pdGVybGltaXQ9IjE2IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==");
        width: 10px;
        height: 10px;
        right: 10px !important;
        line-height: 20px !important;
        bottom: 18px;
    }


    .common-table thead tr th p {
        font-size: var(--fs-13) !important;
    }


    .common-table tbody tr td {
        padding: 13px 20px !important;
        font-size: var(--fs-15) !important;
    }

    .common-table tbody tr td .table-icon {
        height: 15px;
        width: 15px;
    }

    .common-table tbody tr td .profile-icon {
        height: 34px;
        width: 34px;
        font-size: var(--fs-13);
    }

    .common-table tbody tr td a {
        line-height: 17px;
    }

    .common-table tbody tr td a p {
        font-size: var(--fs-15);
    }

    .common-table tbody tr td a p,
    .common-table tbody tr td p {
        line-height: 17px;
    }

    .common-table tbody tr td small {
        font-size: var(--fs-13) !important;
    }

    .common-table tbody tr td.table-check input,
    .common-table thead tr th.table-check input {
        height: 16px !important;
        width: 16px !important;
    }

    .common-table tbody tr td.table-svg svg {
        height: 16px;
        width: 16px;
    }

    .common-table tfoot tr td {
        padding: 19px 26px 19px 20px !important;
        font-size: var(--fs-13) !important;
    }


    .common-table tfoot tr td p {
        font-size: var(--fs-13) !important;
    }

    .common-table .blank-table-view {
        padding: 100px 20px;
        max-width: 450px;
    }

    .common-table .blank-table-view h5 {
        font-size: var(--fs-18) !important;
        line-height: 22px !important;
    }

    .common-table .blank-table-view p {
        font-size: var(--fs-15) !important;
        line-height: 20px !important;
    }

    .custom-table-infotext {
        font-size: var(--fs-15);
    }
}

/* <------- Media Query End -------> */