/* ================================================
   Project: Invitewala Reseller Panel
   Author: DCPL Technology Private Limited.
   
   Note: Not Change Any File Postion in this File.
=================================================== */

.common-card {
    padding: 32px;
}

.common-card .card-header h5 {
    font-size: var(--fs-24) !important;
    color: var(--black-one) !important;
    line-height: 28px !important;
}

.common-card .common-card-header a {
    color: var(--purple) !important;
    line-height: 26px !important;
    font-size: var(--fs-20) !important;
}

.common-card .common-card-header a:hover {
    color: var(--purple-six) !important;
}

.common-card .common-card-header p {
    font-size: var(--fs-18);
    color: var(--muted);
}

.common-card .common-card-header p span {
    color: var(--black-one) !important;
}

.common-card .common-card-body .card-profile .user-icon {
    height: 48px;
    width: 48px;
    background-color: var(--purple);
    font-size: var(--fs-18);
    color: var(--white);
    letter-spacing: 1px;
}

.common-card .common-card-body h6 {
    color: var(--black-one);
    line-height: 22px;
    font-size: var(--fs-20);
}


.common-card .common-card-body .card-content p {
    line-height: 28px;
    font-size: var(--fs-20);
    color: var(--muted);
}

.common-card .common-card-body .card-content .pdf-view {
    width: 160px;
    height: 200px;
    border-color: var(--muted-twentyone);
    overflow: hidden;
}

.common-card .common-card-body .card-content .pdf-view canvas {
    height: 100% !important;
    max-width: 100% !important;
    width: 100% !important;
    object-fit: contain !important;
}

.common-card .common-card-body .status-content {
    gap: 14px;
}

.common-card .common-card-body .status-content .status-icon {
    height: 46px;
    width: 46px;
}

.common-card .common-card-body .status-content .status-icon svg {
    height: 32px;
    width: 32px;
}

.common-card .common-card-body .status-content span {
    font-size: var(--fs-18) !important;
    color: var(--muted) !important;
    line-height: 24px;
}

.common-card .common-card-body .status-content h6.purple-cr {
    color: var(--purple) !important;
}

.common-card .common-card-body .status-content h6.green-cr {
    color: var(--green) !important;
}

.common-card .common-card-body .status-content h6.red-cr {
    color: var(--red) !important;
}

.common-card .common-card-body .status-content h6.yellow-cr-cr {
    color: var(--orange) !important;
}

.common-card .common-card-body .number-chip {
    font-size: var(--fs-19);
    line-height: var(--lh-20);
    padding: 4px 12px;
    background-color: var(--muted-seven);
    color: var(--muted-one);
    border-radius: 4px;
    min-width: 36px;
    text-align: center;
}

.common-card .common-card-body .status-content.success-status .status-icon {
    background-color: var(--green-one) !important;
    color: var(--green) !important;
}

.common-card .common-card-body .status-content.success-status .status-black {
    color: var(--black-one) !important;
}

.common-card .common-card-body .status-content.success-status h6 {
    color: var(--green) !important;
}

.common-card .common-card-body .status-content.success-status .status-icon svg {
    color: var(--green) !important;
}

.common-card .common-card-body .status-content.danger-status .status-icon {
    background-color: var(--pink) !important;
    color: var(--red) !important;
}

.common-card .common-card-body .status-content.danger-status h6 {
    color: var(--red) !important;
}

.common-card .common-card-body .status-content.danger-status .status-icon svg {
    color: var(--red) !important;
}


/* danger-status */

.common-card .common-card-body .status-content.primary-status .status-icon {
    background-color: var(--purple-two) !important;
    color: var(--purple) !important;
}

.common-card .common-card-body .status-content.primary-status .status-icon svg {
    color: var(--purple) !important;
}

/* Warning-status */

.common-card .common-card-body .status-content.warning-status .status-icon {
    background-color: var(--orange-one) !important;
    color: var(--orange) !important;
}

.common-card .common-card-body .status-content.warning-status .status-icon svg {
    color: var(--orange) !important;
}



/* <------- Media Query Start -------> */
@media screen and (max-width:1920px) {

    .common-card {
        padding: 24px;
    }

    .common-card .card-header h5 {
        font-size: var(--fs-18) !important;
        line-height: 28px !important;
    }

    .common-card .common-card-header a {
        line-height: 22px !important;
        font-size: var(--fs-15) !important;
    }

    .common-card .common-card-header p {
        font-size: var(--fs-14);
    }

    .common-card .common-card-body .card-profile .user-icon {
        height: 40px;
        width: 40px;
        font-size: var(--fs-13);
    }

    .common-card .common-card-body h6 {
        line-height: 22px;
        font-size: var(--fs-15);
    }


    .common-card .common-card-body .card-content p {
        line-height: 20px;
        font-size: var(--fs-15);
    }

    .common-card .common-card-body .card-content .pdf-view {
        width: 106px;
        height: 149px;
    }

    .common-card .common-card-body .card-content .pdf-view canvas {
        height: 100% !important;
        max-width: 100% !important;
        width: 100% !important;
    }

    .common-card .common-card-body .status-content {
        gap: 12px;
    }

    .common-card .common-card-body .status-content .status-icon {
        height: 40px;
        width: 40px;
    }

    .common-card .common-card-body .status-content .status-icon svg {
        height: 28px;
        width: 28px;
    }

    .common-card .common-card-body .status-content span {
        font-size: var(--fs-13) !important;
        line-height: 18px;
    }

    .common-card .common-card-body .number-chip {
        font-size: var(--fs-14);
        line-height: var(--lh-15);
        padding: 2px 8px;
    }

}

/* <------- Media Query End -------> */