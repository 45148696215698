/*
=========================================================
* Dash UI - Bootstrap 5 Admin & Dashboard Theme
=========================================================
* Product Page: https://codescandy.com/dashui/index.html
* Copyright 2023 Codescandy (https://codescandy.com/)
* Designed and coded by https://codescandy.com
========================================================= */

// theme variables
@import "theme/variables";

//utilities
@import "theme/utilities";

// Bootstrap
// @import "~bootstrap/scss/bootstrap";

// Theme
@import "theme/theme";

@import "./pages/dashboard.scss";

@import "./pages/credits-log.scss";

@import "theme/logical/logical";

.heading-permalink {
    color: transparent;
    margin-left: -2rem;
    &:hover {
        color: transparent;
    }

    &::before {
        color: rgba(82, 95, 127, 0);
        content: "#";
    }
    &:hover:before {
        color: $primary;
    }
}
